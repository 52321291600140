.location-picker__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(48, 48, 48, 0.3);
    backdrop-filter: blur(3px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-picker__gui {
    width: 60%;
    height: 60%;
}

.location-picker__ctrl {
    background-color: white;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.location-picker__map {
    width: 100%;
    height: 100%;
}