.page-loading-bar {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.page-loading-bar:after {
    content: " ";
    display: block;
    width: 48px;
    height: 48px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(48, 48, 48);
    border-color: rgb(48, 48, 48) transparent rgb(48, 48, 48) transparent;
    animation: lds-dual-ring 1s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
