@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx1XHgciw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx0XHgciw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3EZQNVED7rKGKxtqIqX5EUCEx6XHg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: "Qartella";
  src: local("Qartella"), url(/static/media/Qartella-Bold.bbd928dd.otf) format("opentype"); }

@font-face {
  font-family: "Qartella";
  src: local("Qartella"), url(/static/media/Qartella-ExtraBold.64a14859.otf) format("opentype"); }

@font-face {
  font-family: "Qartella";
  src: local("Qartella"), url(/static/media/Qartella-Regular.42934c92.otf) format("opentype"); }

@font-face {
  font-family: "Qartella";
  src: local("Qartella"), url(/static/media/Qartella-Heavy.965d3fce.woff) format("woff"); }

html,
body {
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  min-height: 100vh; }

.frezee-screen {
  overflow-x: hidden !important;
  overflow-y: hidden !important; }

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 19px; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1599px) and (min-width: 1360px) {
  html,
  body {
    font-size: 18px; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 16px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    font-size: 13px; } }

@media screen and (max-width: 319px) and (min-width: 280px) {
  html,
  body {
    font-size: 9px; } }

img {
  width: 100%; }

Input,
input,
textarea,
select {
  width: 100%; }

Input,
input,
textarea,
select,
button,
a:focus {
  outline: none !important; }

button {
  cursor: pointer; }

.title-wrapper {
  display: flex;
  justify-content: center;
  text-align: center; }
  .title-wrapper .title {
    text-align: center;
    font-family: "Qartella", sans-serif;
    font-weight: 900;
    font-size: 1.82rem;
    line-height: 2.21rem;
    color: #010101;
    position: relative; }
    .title-wrapper .title::after {
      content: "";
      display: block;
      width: 90px;
      height: 5px;
      background-color: #26ade3;
      border-radius: 30px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -1rem; }

.mb-1 {
  margin-bottom: 2rem; }

.login-wrapper {
  display: flex;
  width: 100%;
  max-height: 100vh;
  overflow: hidden; }
  @media (max-height: 700px) {
    .login-wrapper {
      max-height: unset;
      overflow: unset; } }
  @media (max-width: 991px) {
    .login-wrapper {
      display: block;
      max-height: unset; } }
  @media (max-width: 370px) {
    .login-wrapper {
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column; } }
  .login-wrapper .left,
  .login-wrapper .right {
    width: 50%;
    height: 100vh; }
    @media (max-height: 700px) {
      .login-wrapper .left,
      .login-wrapper .right {
        max-height: unset;
        height: unset; } }
    @media (max-width: 991px) {
      .login-wrapper .left,
      .login-wrapper .right {
        width: 100%;
        height: unset; } }
  .login-wrapper .right {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100vh; }
    @media (max-height: 700px) {
      .login-wrapper .right {
        height: unset; } }
    @media (max-width: 370px) {
      .login-wrapper .right {
        width: 90%; } }
    @media (max-width: 991px) {
      .login-wrapper .right {
        margin-top: 3.15rem;
        margin-bottom: 3.15rem; } }
    .login-wrapper .right .header > div {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      @media (max-width: 991px) {
        .login-wrapper .right .header > div {
          justify-content: center;
          text-align: center; } }
      .login-wrapper .right .header > div h1 {
        font-family: "Qartella", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 1.82rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        margin-right: 8px;
        margin-top: 15px;
        margin-bottom: 0; }
      .login-wrapper .right .header > div svg {
        max-width: 12.73rem; }
    .login-wrapper .right .header p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.84rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 1rem;
      opacity: 0.6; }
    .login-wrapper .right .header .form {
      display: block; }
      @media (max-width: 991px) {
        .login-wrapper .right .header .form {
          text-align: left; } }
      .login-wrapper .right .header .form .remember {
        display: flex;
        justify-content: space-between;
        align-items: baseline; }
        .login-wrapper .right .header .form .remember .checkbox-wrapper {
          display: flex;
          justify-content: flex-start; }
          .login-wrapper .right .header .form .remember .checkbox-wrapper .checkbox {
            width: 1.36rem;
            height: 1.36rem;
            background: #ffffff;
            border: 1px solid #010101;
            box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.25);
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden; }
            .login-wrapper .right .header .form .remember .checkbox-wrapper .checkbox .checked {
              -webkit-transform: rotate(45deg);
                      transform: rotate(45deg);
              height: 55%;
              margin-left: 42%;
              border-bottom: 3px solid #010101;
              border-right: 3px solid #010101;
              position: absolute;
              top: 0;
              left: 0;
              right: 8px;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto; }
          .login-wrapper .right .header .form .remember .checkbox-wrapper p {
            margin: 0 0 0 .75rem;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 0.84rem;
            line-height: 1.26rem;
            letter-spacing: 0.1px;
            text-decoration: none;
            color: #010101;
            opacity: 0.8; }
        .login-wrapper .right .header .form .remember a {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.84rem;
          line-height: 1.26rem;
          letter-spacing: 0.1px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #010101;
          opacity: 0.8; }
    .login-wrapper .right .header .error {
      color: red; }
    .login-wrapper .right .header .login {
      margin-top: 1.26rem;
      background: #3176bc;
      box-shadow: 0px 0px 16px rgba(49, 118, 188, 0.18), inset 0px 4px 12px rgba(28, 144, 227, 0.18);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.73rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      width: 100%;
      border: 0;
      padding-top: 0.78rem;
      padding-bottom: 0.78rem; }
      .login-wrapper .right .header .login:disabled {
        cursor: not-allowed;
        background: lightblue; }
    .login-wrapper .right .header .dont-account {
      text-align: center; }
      .login-wrapper .right .header .dont-account h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8; }
        .login-wrapper .right .header .dont-account h6 a {
          font-weight: 700;
          color: #010101; }
    .login-wrapper .right .header .another-options {
      text-align: center; }
      .login-wrapper .right .header .another-options h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.89rem; }
        .login-wrapper .right .header .another-options h4::after {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-left: 8px; }
        .login-wrapper .right .header .another-options h4::before {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-right: 8px; }
      .login-wrapper .right .header .another-options .button-warpper {
        display: flex;
        justify-content: center; }
        .login-wrapper .right .header .another-options .button-warpper button {
          margin-right: 1.26rem;
          border: 0.7px solid rgba(1, 1, 1, 0.3);
          box-sizing: border-box;
          border-radius: 5px;
          background-color: transparent;
          padding: 1.15rem 1.25rem; }

.password-input-wrapper input {
  padding-left: 2.94rem;
  padding-bottom: 0.78rem;
  padding-top: 0.78rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #3176bc; }

.password-input-wrapper label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #010101; }

.password-input-wrapper .input-password {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0.57rem; }
  .password-input-wrapper .input-password input {
    border: 2px solid rgba(1, 1, 1, 0.6);
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.13), inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    color: #010101;
    z-index: 1; }
  .password-input-wrapper .input-password button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 1.36rem;
    top: 0;
    bottom: 0;
    margin: auto; }
    .password-input-wrapper .input-password button svg {
      left: 0;
      z-index: 2; }
  .password-input-wrapper .input-password svg {
    position: absolute;
    left: 1.15rem;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2; }

.input-email-wrapper input {
  padding-left: 2.94rem;
  padding-bottom: 0.78rem;
  padding-top: 0.78rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #3176bc; }

.input-email-wrapper label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #010101; }

.input-email-wrapper .email-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0.57rem; }
  .input-email-wrapper .email-wrapper input {
    border: 2px solid rgba(49, 118, 188, 0.7);
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.13), inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 10px; }
  .input-email-wrapper .email-wrapper svg {
    position: absolute;
    left: 1.15rem;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2; }

.input-location-wrapper input {
  padding-left: 2.94rem;
  padding-bottom: 0.78rem;
  padding-top: 0.78rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #3176bc; }

.input-location-wrapper label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #010101; }

.input-location-wrapper .location-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0.57rem; }
  .input-location-wrapper .location-wrapper input {
    border: 2px solid rgba(1, 1, 1, 0.6);
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.13), inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    color: #010101;
    z-index: 1; }
  .input-location-wrapper .location-wrapper button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto; }
    .input-location-wrapper .location-wrapper button svg {
      left: 0;
      z-index: 2; }
  .input-location-wrapper .location-wrapper svg {
    position: absolute;
    left: 1.15rem;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2; }

@media (max-height: 850px) {
  .carousel-wrapper {
    height: 100%; } }

.carousel-wrapper .slider-container {
  max-height: 100vh;
  overflow: hidden; }
  @media (max-height: 850px) {
    .carousel-wrapper .slider-container {
      height: 100%;
      max-height: 100%; } }
  @media (max-width: 991px) {
    .carousel-wrapper .slider-container {
      max-height: 24.26rem; } }
  .carousel-wrapper .slider-container .slider-frame {
    height: 100% !important; }
    .carousel-wrapper .slider-container .slider-frame .slider-list {
      height: 100% !important; }
    .carousel-wrapper .slider-container .slider-frame .slide img,
    .carousel-wrapper .slider-container .slider-frame .slide-visible img {
      height: 100vh;
      object-fit: cover;
      width: 97%; }
      @media (max-height: 850px) {
        .carousel-wrapper .slider-container .slider-frame .slide img,
        .carousel-wrapper .slider-container .slider-frame .slide-visible img {
          height: 100%;
          max-height: 100%; } }
  .carousel-wrapper .slider-container .slider-control-centerleft,
  .carousel-wrapper .slider-container .slider-control-centerright {
    display: none; }
  .carousel-wrapper .slider-container .slider-control-bottomcenter {
    bottom: 4.21rem !important; }
    @media (max-width: 991px) {
      .carousel-wrapper .slider-container .slider-control-bottomcenter {
        bottom: 2.21rem !important; } }
    .carousel-wrapper .slider-container .slider-control-bottomcenter ul li {
      margin-right: 1rem;
      background-color: transparent;
      border-radius: 50%;
      width: 0.78rem;
      height: 0.78rem;
      border: 1px solid white; }
      .carousel-wrapper .slider-container .slider-control-bottomcenter ul li button svg {
        visibility: hidden; }
    .carousel-wrapper .slider-container .slider-control-bottomcenter ul li.active {
      background-color: #ffffff; }

.signup-wrapper {
  display: flex;
  width: 100%;
  max-height: 100vh;
  overflow: hidden; }
  @media (max-height: 850px) {
    .signup-wrapper {
      max-height: unset;
      overflow: unset; } }
  @media (max-width: 991px) {
    .signup-wrapper {
      display: block;
      max-height: unset; } }
  @media (max-width: 370px) {
    .signup-wrapper {
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column; } }
  .signup-wrapper .left,
  .signup-wrapper .right {
    width: 50%;
    height: 100vh; }
    @media (max-height: 850px) {
      .signup-wrapper .left,
      .signup-wrapper .right {
        max-height: unset;
        height: unset; } }
    @media (max-width: 991px) {
      .signup-wrapper .left,
      .signup-wrapper .right {
        width: 100%;
        height: unset; } }
  .signup-wrapper .right {
    justify-content: center;
    display: flex;
    align-items: center; }
    @media (max-height: 850px) {
      .signup-wrapper .right {
        height: unset; } }
    @media (max-width: 370px) {
      .signup-wrapper .right {
        width: 90%; } }
    @media (max-width: 991px) {
      .signup-wrapper .right {
        margin-top: 3.15rem;
        margin-bottom: 3.15rem; } }
    .signup-wrapper .right .header > div {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      @media (max-width: 991px) {
        .signup-wrapper .right .header > div {
          justify-content: center;
          text-align: center; } }
      .signup-wrapper .right .header > div h1 {
        font-family: "Qartella", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 1.82rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        margin-right: 8px;
        margin-top: 15px;
        margin-bottom: 0; }
      .signup-wrapper .right .header > div svg {
        max-width: 12.73rem; }
    .signup-wrapper .right .header p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.84rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 1rem;
      opacity: 0.6; }
    .signup-wrapper .right .header .form {
      max-width: 26.25rem;
      display: block; }
      @media (max-width: 991px) {
        .signup-wrapper .right .header .form {
          text-align: left; } }
      .signup-wrapper .right .header .form .remember {
        display: flex;
        justify-content: space-between;
        align-items: baseline; }
        .signup-wrapper .right .header .form .remember .checkbox-wrapper .checkbox {
          width: 1.36rem;
          height: 1.36rem;
          background: #ffffff;
          border: 1px solid #010101;
          box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.25);
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          overflow: hidden; }
          .signup-wrapper .right .header .form .remember .checkbox-wrapper .checkbox .checked {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            height: 55%;
            margin-left: 42%;
            border-bottom: 3px solid #010101;
            border-right: 3px solid #010101;
            position: absolute;
            top: 0;
            left: 0;
            right: 8px;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto; }
        .signup-wrapper .right .header .form .remember a {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.84rem;
          line-height: 1.26rem;
          letter-spacing: 0.1px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #010101;
          opacity: 0.8; }
    .signup-wrapper .right .header .login {
      margin-top: 1.26rem;
      background: #3176bc;
      box-shadow: 0px 0px 16px rgba(49, 118, 188, 0.18), inset 0px 4px 12px rgba(28, 144, 227, 0.18);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.73rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      width: 100%;
      border: 0;
      padding-top: 0.78rem;
      padding-bottom: 0.78rem; }
      .signup-wrapper .right .header .login:disabled {
        cursor: not-allowed;
        background: lightblue; }
    .signup-wrapper .right .header .dont-account {
      text-align: center; }
      .signup-wrapper .right .header .dont-account h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8; }
        .signup-wrapper .right .header .dont-account h6 a {
          font-weight: 700;
          color: #010101; }
    .signup-wrapper .right .header .another-options {
      text-align: center; }
      .signup-wrapper .right .header .another-options h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.89rem; }
        .signup-wrapper .right .header .another-options h4::after {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-left: 8px; }
        .signup-wrapper .right .header .another-options h4::before {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-right: 8px; }
      .signup-wrapper .right .header .another-options .button-warpper {
        display: flex;
        justify-content: center; }
        .signup-wrapper .right .header .another-options .button-warpper button {
          margin-right: 1.26rem;
          border: 0.7px solid rgba(1, 1, 1, 0.3);
          box-sizing: border-box;
          border-radius: 5px;
          background-color: transparent;
          padding: 1.15rem 1.25rem; }

.forgot-password-wrapper {
  display: flex;
  width: 100%;
  max-height: 100vh;
  overflow: hidden; }
  @media (max-width: 991px) {
    .forgot-password-wrapper {
      display: block;
      max-height: unset; } }
  @media (max-width: 370px) {
    .forgot-password-wrapper {
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column; } }
  .forgot-password-wrapper .left,
  .forgot-password-wrapper .right {
    width: 50%;
    height: 100vh; }
    @media (max-width: 991px) {
      .forgot-password-wrapper .left,
      .forgot-password-wrapper .right {
        width: 100%;
        height: unset; } }
  .forgot-password-wrapper .right {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative; }
    .forgot-password-wrapper .right .arrow-left {
      top: 2.52rem;
      left: 2.52rem;
      position: absolute;
      background: #ffffff;
      border: 1px solid #3176bc;
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;
      align-items: center;
      text-align: center;
      width: 2.21rem;
      height: 2.21rem;
      justify-content: center; }
      .forgot-password-wrapper .right .arrow-left svg {
        width: 0.52rem; }
      @media (max-width: 991px) {
        .forgot-password-wrapper .right .arrow-left {
          top: 0; } }
      @media (max-width: 767px) {
        .forgot-password-wrapper .right .arrow-left {
          left: 1rem; } }
    @media (max-width: 370px) {
      .forgot-password-wrapper .right {
        width: 90%; } }
    @media (max-width: 991px) {
      .forgot-password-wrapper .right {
        margin-top: 3.15rem;
        margin-bottom: 3.15rem; } }
    .forgot-password-wrapper .right .header > div {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      @media (max-width: 991px) {
        .forgot-password-wrapper .right .header > div {
          justify-content: center;
          text-align: center; } }
      .forgot-password-wrapper .right .header > div h1 {
        font-family: "Qartella", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 1.82rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        margin-right: 8px;
        margin-top: 15px;
        margin-bottom: 1.73rem; }
      .forgot-password-wrapper .right .header > div svg {
        max-width: 12.73rem; }
    .forgot-password-wrapper .right .header p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.84rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 1rem;
      opacity: 0.6;
      max-width: 23.31rem; }
    .forgot-password-wrapper .right .header .form {
      display: block; }
      @media (max-width: 991px) {
        .forgot-password-wrapper .right .header .form {
          text-align: left; } }
      .forgot-password-wrapper .right .header .form .remember {
        display: flex;
        justify-content: space-between;
        align-items: baseline; }
        .forgot-password-wrapper .right .header .form .remember .checkbox-wrapper .checkbox {
          width: 1.36rem;
          height: 1.36rem;
          background: #ffffff;
          border: 1px solid #010101;
          box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.25);
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          overflow: hidden; }
          .forgot-password-wrapper .right .header .form .remember .checkbox-wrapper .checkbox .checked {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            height: 55%;
            margin-left: 42%;
            border-bottom: 3px solid #010101;
            border-right: 3px solid #010101;
            position: absolute;
            top: 0;
            left: 0;
            right: 8px;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto; }
        .forgot-password-wrapper .right .header .form .remember a {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.84rem;
          line-height: 1.26rem;
          letter-spacing: 0.1px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #010101;
          opacity: 0.8; }
    .forgot-password-wrapper .right .header .login {
      margin-top: 1.26rem;
      background: #3176bc;
      box-shadow: 0px 0px 16px rgba(49, 118, 188, 0.18), inset 0px 4px 12px rgba(28, 144, 227, 0.18);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.73rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      width: 100%;
      border: 0;
      padding-top: 0.78rem;
      padding-bottom: 0.78rem; }
    .forgot-password-wrapper .right .header .dont-account {
      text-align: center; }
      .forgot-password-wrapper .right .header .dont-account h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8; }
        .forgot-password-wrapper .right .header .dont-account h6 a {
          font-weight: 700;
          color: #010101; }
    .forgot-password-wrapper .right .header .another-options {
      text-align: center; }
      .forgot-password-wrapper .right .header .another-options h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.89rem; }
        .forgot-password-wrapper .right .header .another-options h4::after {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-left: 8px; }
        .forgot-password-wrapper .right .header .another-options h4::before {
          content: "";
          width: 80px;
          height: 2px;
          background-color: #010101;
          display: inline-block;
          align-items: center;
          margin-right: 8px; }
      .forgot-password-wrapper .right .header .another-options .button-warpper {
        display: flex;
        justify-content: center; }
        .forgot-password-wrapper .right .header .another-options .button-warpper button {
          margin-right: 1.26rem;
          border: 0.7px solid rgba(1, 1, 1, 0.3);
          box-sizing: border-box;
          border-radius: 5px;
          background-color: transparent;
          padding: 1.15rem 1.25rem; }

.navbar-wrapper .navbar {
  width: 98%;
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.15);
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 1.52rem;
  padding-bottom: 1.52rem; }
  .navbar-wrapper .navbar .hamburger {
    position: absolute;
    left: 2.1rem;
    top: 1.52rem;
    bottom: 1.52rem;
    background-color: transparent;
    border: 0; }
    .navbar-wrapper .navbar .hamburger svg {
      max-width: 1.89rem; }
  .navbar-wrapper .navbar svg {
    max-width: 6.94rem; }
  .navbar-wrapper .navbar .sidebar {
    z-index: 100;
    padding-top: 1.21rem;
    padding-left: 1.42rem;
    padding-right: 1.42rem;
    padding-bottom: 2.1rem;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.25);
    border-radius: 0px 0px 15px 0px; }
    .navbar-wrapper .navbar .sidebar .arrow-left {
      display: flex;
      justify-content: flex-end; }
      .navbar-wrapper .navbar .sidebar .arrow-left button {
        background: #3176bc;
        opacity: 0.7;
        box-shadow: 0px 4px 12px rgba(49, 118, 188, 0.13);
        border-radius: 5px;
        border: 0;
        padding: 0.47rem;
        margin-bottom: 1.15rem; }
    .navbar-wrapper .navbar .sidebar .menu {
      display: flex;
      flex-direction: column;
      padding-bottom: 5.68rem; }
    .navbar-wrapper .navbar .sidebar .item {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.73rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #3176bc;
      text-decoration: unset;
      background-color: #ffffff;
      border: 0;
      display: flex;
      align-items: center;
      padding-top: 0.63rem;
      padding-bottom: 0.63rem;
      padding-left: 1rem;
      width: 13.15rem;
      margin-bottom: 0.5rem;
      white-space: nowrap; }
      .navbar-wrapper .navbar .sidebar .item svg {
        margin-right: 0.94rem;
        stroke: #3176bc; }
    .navbar-wrapper .navbar .sidebar .active {
      background: #3176bc;
      box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
      border-radius: 10px;
      color: #ffffff; }
      .navbar-wrapper .navbar .sidebar .active svg {
        stroke: #ffffff; }
    .navbar-wrapper .navbar .sidebar .active-user {
      background: #3176bc;
      box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
      border-radius: 10px;
      color: #ffffff; }
      .navbar-wrapper .navbar .sidebar .active-user svg {
        stroke: #ffffff; }
    .navbar-wrapper .navbar .sidebar .logout svg {
      fill: #ffffff !important; }

@media (max-width: 991px) {
  .listing-wrapper {
    margin-top: 2rem; } }

.listing-wrapper h2 {
  font-family: "Qartella", sans-serif;
  font-weight: 800;
  font-size: 1.05rem;
  line-height: 1.1rem;
  letter-spacing: 0.1px;
  color: #010101;
  margin-bottom: 1.68rem; }

.listing-wrapper .listing-content-wrapper {
  display: grid;
  grid-template-columns: 2.5fr 2.5fr;
  grid-column-gap: 3.26rem;
  -webkit-column-gap: 3.26rem;
          column-gap: 3.26rem;
  grid-row-gap: 2.26rem;
  row-gap: 2.26rem; }
  @media (max-width: 991px) {
    .listing-wrapper .listing-content-wrapper {
      display: block; } }
  .listing-wrapper .listing-content-wrapper .listing {
    position: relative; }
    .listing-wrapper .listing-content-wrapper .listing a {
      text-decoration: unset;
      cursor: pointer; }
      @media (max-width: 991px) {
        .listing-wrapper .listing-content-wrapper .listing a {
          margin-bottom: 0.5rem; } }
      .listing-wrapper .listing-content-wrapper .listing a .image {
        transition: 0.3s;
        position: relative;
        overflow: hidden;
        transition: 0.3s; }
        .listing-wrapper .listing-content-wrapper .listing a .image img {
          height: 23.68rem; }
        .listing-wrapper .listing-content-wrapper .listing a .image:hover {
          -webkit-transform: scale(1.01);
                  transform: scale(1.01); }
        .listing-wrapper .listing-content-wrapper .listing a .image .overlay {
          position: absolute;
          bottom: 9px;
          left: 0;
          right: 0;
          width: 96.4%;
          background: rgba(1, 1, 1, 0.4);
          padding-left: 1.52rem;
          padding-top: 0.84rem;
          padding-bottom: 0.84rem; }
          .listing-wrapper .listing-content-wrapper .listing a .image .overlay h4 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.84rem;
            line-height: 1.05rem;
            letter-spacing: 0.1px;
            color: #ffffff;
            margin: 0;
            padding-bottom: 0.42rem; }
      .listing-wrapper .listing-content-wrapper .listing a .info {
        padding-left: 3.15rem;
        padding-right: 3.15rem;
        display: flex;
        justify-content: space-between; }
        .listing-wrapper .listing-content-wrapper .listing a .info > div {
          text-align: center; }
          .listing-wrapper .listing-content-wrapper .listing a .info > div h3 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 1.05rem;
            line-height: 1.05rem;
            letter-spacing: 0.1px;
            color: #010101; }
          .listing-wrapper .listing-content-wrapper .listing a .info > div h4 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.73rem;
            line-height: 1.05rem;
            letter-spacing: 0.1px;
            color: #010101;
            opacity: 0.5; }

.home-content-wrapper {
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
  margin: 1rem 2.1rem 3.36rem 2.1rem; }
  @media (max-width: 767px) {
    .home-content-wrapper {
      margin: 1rem 0.5rem 3.36rem 0.5rem; } }
  .home-content-wrapper .title {
    padding-top: 2.52rem;
    padding-left: 2.52rem;
    padding-right: 1.3rem; }
    @media (max-width: 991px) {
      .home-content-wrapper .title {
        padding-left: 1.3rem; } }
    .home-content-wrapper .title h6 {
      font-family: "Qartella", sans-serif;
      font-weight: 900;
      font-size: 1.52rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0; }
  .home-content-wrapper .subtitle {
    margin-top: 1.57rem;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.13);
    padding-left: 2.52rem;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .home-content-wrapper .subtitle svg {
      margin-right: 0.68rem; }
    .home-content-wrapper .subtitle h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 0.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin: 0; }
  .home-content-wrapper .home-content {
    padding: 2.52rem 1.3rem 5rem 2.52rem; }
    @media (max-width: 767px) {
      .home-content-wrapper .home-content {
        padding: 2.52rem 1.3rem 5rem 1.3rem; } }
  .home-content-wrapper .filter {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      .home-content-wrapper .filter .export {
        display: none; } }
    .home-content-wrapper .filter .export a {
      background: #008575;
      box-shadow: 0px 0px 12px rgba(0, 133, 117, 0.13);
      color: #ffffff;
      display: flex;
      align-items: center;
      border: 0;
      padding: 0.78rem 2.21rem;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.78rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s; }
      .home-content-wrapper .filter .export a svg {
        margin-right: 0.6rem; }
      .home-content-wrapper .filter .export a:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  .home-content-wrapper .statistic-charts-wrapper {
    position: relative; }
    .home-content-wrapper .statistic-charts-wrapper h3 {
      font-family: "Qartella", sans-serif;
      font-weight: 800;
      font-size: 1.05rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0;
      margin-bottom: 1.52rem; }
    .home-content-wrapper .statistic-charts-wrapper .statistic-charts {
      display: grid;
      grid-template-columns: 2.5fr 2.5fr;
      grid-column-gap: 3.21rem;
      -webkit-column-gap: 3.21rem;
              column-gap: 3.21rem;
      grid-row-gap: 2.36rem;
      row-gap: 2.36rem;
      margin-bottom: 3.15rem; }
      @media (max-width: 991px) {
        .home-content-wrapper .statistic-charts-wrapper .statistic-charts {
          display: unset; } }

.wrapper-date-input {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .wrapper-date-input {
      display: block; } }
  .wrapper-date-input .content {
    margin-right: 0.42rem; }
    @media (max-width: 767px) {
      .wrapper-date-input .content {
        margin-right: unset;
        margin-bottom: 0.63rem; } }
    .wrapper-date-input .content .dropdown-wrapper {
      position: relative;
      border: 1px solid rgba(1, 1, 1, 0.1);
      width: 10.47rem;
      display: flex;
      justify-content: space-between;
      padding: 0.42rem;
      cursor: pointer;
      align-items: center; }
      @media (max-width: 767px) {
        .wrapper-date-input .content .dropdown-wrapper {
          width: unset; } }
      .wrapper-date-input .content .dropdown-wrapper h2 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 13.95px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8; }
      .wrapper-date-input .content .dropdown-wrapper .dropdown {
        position: absolute;
        background: #ffffff;
        border: 1px solid rgba(1, 1, 1, 0.1);
        z-index: 99;
        width: 100%;
        left: 0;
        top: 55px;
        padding-top: 0.63rem;
        padding-bottom: 0.63rem; }
        .wrapper-date-input .content .dropdown-wrapper .dropdown h6 {
          padding-left: 2.31rem;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 13.95px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: #010101;
          opacity: 0.8;
          margin-top: 0;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          margin-bottom: 0; }
          .wrapper-date-input .content .dropdown-wrapper .dropdown h6:hover {
            background-color: #3176bc; }
  .wrapper-date-input .buttons {
    margin-right: 1.05rem; }
    @media (max-width: 767px) {
      .wrapper-date-input .buttons {
        float: left; } }
    .wrapper-date-input .buttons button {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.71rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      border: 0;
      background-color: #ffffff;
      padding: 1.1rem 1.4rem; }
    .wrapper-date-input .buttons .active {
      background-color: #3176bc;
      color: #ffffff; }
  @media (max-width: 767px) {
    .wrapper-date-input .range {
      float: right;
      margin-top: 1rem; } }
  .wrapper-date-input .range h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.71rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8;
    margin: 0; }

.overview-wrapper {
  margin-top: 3.15rem;
  margin-bottom: 3.15rem; }
  .overview-wrapper h3 {
    font-family: "Qartella", sans-serif;
    font-weight: 800;
    font-size: 1.05rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    margin: 0;
    margin-bottom: 1.68rem; }
  .overview-wrapper .overview {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr 2.5fr 2.5fr;
    grid-column-gap: 1.47rem;
    -webkit-column-gap: 1.47rem;
            column-gap: 1.47rem;
    grid-row-gap: 0.63rem;
    row-gap: 0.63rem; }
    @media (max-width: 991px) {
      .overview-wrapper .overview {
        grid-template-columns: 2.5fr 2.5fr; } }
    @media (max-width: 767px) {
      .overview-wrapper .overview {
        grid-template-columns: 2.5fr; } }
    .overview-wrapper .overview .box {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      padding: 1.26rem 0.8rem 2rem 1.26rem; }
      @media (max-width: 767px) {
        .overview-wrapper .overview .box {
          max-width: 80%; } }
      .overview-wrapper .overview .box .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.68rem; }
        .overview-wrapper .overview .box .header h3 {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 0.71rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #010101;
          margin: 0; }
      .overview-wrapper .overview .box h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 1.68rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        color: #010101;
        margin: 0; }

.pie-chart-wrapper {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 2rem 4.73rem 2.15rem 1.26rem;
  max-height: 18.5rem;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  background-color: rgba(49, 118, 188, 0.1); }
  @media (max-width: 991px) {
    .pie-chart-wrapper {
      max-height: unset; } }
  @media (max-width: 767px) {
    .pie-chart-wrapper {
      padding: 2rem 1.26rem 2.15rem 1.26rem;
      text-align: center; } }
  .pie-chart-wrapper h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #010101;
    margin: 0;
    margin-bottom: 1.26rem; }
  .pie-chart-wrapper .my-pie-chart-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767px) {
      .pie-chart-wrapper .my-pie-chart-container {
        flex-direction: column; } }
    .pie-chart-wrapper .my-pie-chart-container .contries {
      max-width: 38%; }
      @media (max-width: 767px) {
        .pie-chart-wrapper .my-pie-chart-container .contries {
          max-width: unset;
          width: 100%; } }
      .pie-chart-wrapper .my-pie-chart-container .contries .contry {
        display: flex;
        align-items: center;
        margin-bottom: 0.42rem;
        justify-content: space-between; }
        .pie-chart-wrapper .my-pie-chart-container .contries .contry .contry-name {
          display: flex;
          align-items: center; }
          .pie-chart-wrapper .my-pie-chart-container .contries .contry .contry-name span {
            width: 10px;
            height: 10px;
            background-color: #d11a2a;
            display: inline-block;
            margin-right: 0.84rem;
            border-radius: 50%; }
          .pie-chart-wrapper .my-pie-chart-container .contries .contry .contry-name h6 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.73rem;
            line-height: 1.1rem;
            letter-spacing: 0.1px;
            color: #010101;
            opacity: 0.8;
            margin: 0; }
        .pie-chart-wrapper .my-pie-chart-container .contries .contry .text {
          display: flex;
          align-items: baseline; }
          .pie-chart-wrapper .my-pie-chart-container .contries .contry .text h5 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.73rem;
            line-height: 1.1rem;
            letter-spacing: 0.1px;
            color: #010101;
            opacity: 0.8;
            margin: 0;
            margin-right: 1.26rem; }
          .pie-chart-wrapper .my-pie-chart-container .contries .contry .text h4 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.63rem;
            line-height: 1.1rem;
            text-align: right;
            letter-spacing: 0.1px;
            color: #010101;
            opacity: 0.5;
            margin: 0; }
  .pie-chart-wrapper #my-pie-chart {
    border-radius: 50%;
    width: 150px;
    height: 150px; }

.line-chart-wrapper {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 0 1.36rem 3.26rem 1.36rem;
  height: 19.15rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative; }
  @media (max-width: 991px) {
    .line-chart-wrapper {
      margin-bottom: 2.36rem; } }
  .line-chart-wrapper .header {
    display: flex;
    justify-content: space-between; }
    .line-chart-wrapper .header h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.73rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0;
      margin-bottom: 1.26rem; }
    .line-chart-wrapper .header h5 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 1.68rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0; }

.dashboard-listing-wrapper {
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
  margin: 0.97rem 3.78rem 3.15rem 0.42rem; }
  @media (max-width: 991px) {
    .dashboard-listing-wrapper {
      margin: 0.97rem 1.68rem 3.15rem 1.68rem; } }
  @media (max-width: 767px) {
    .dashboard-listing-wrapper {
      margin: 0.97rem 0.72rem 3.15rem 0.42rem; } }
  .dashboard-listing-wrapper .dashboard-listing-content {
    padding: 2.63rem 1.15rem 2rem 4.36rem; }
    @media (max-width: 991px) {
      .dashboard-listing-wrapper .dashboard-listing-content {
        padding: 2.63rem 1.68rem 2rem 1.68rem; } }
    @media (max-width: 767px) {
      .dashboard-listing-wrapper .dashboard-listing-content {
        padding: 2.63rem 0.84rem 2rem 0.84rem; } }
    .dashboard-listing-wrapper .dashboard-listing-content h1 {
      font-family: "Qartella", sans-serif;
      font-weight: 400;
      font-size: 1.52rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0; }
    .dashboard-listing-wrapper .dashboard-listing-content .content {
      margin-top: 1.68rem; }
      .dashboard-listing-wrapper .dashboard-listing-content .content button {
        border-radius: 5px;
        background-color: #010101;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 1.05rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #ffffff;
        border: 0;
        padding: 0.84rem 1.52rem; }
        @media (max-width: 991px) {
          .dashboard-listing-wrapper .dashboard-listing-content .content button {
            width: 100%; } }
      .dashboard-listing-wrapper .dashboard-listing-content .content .filters {
        display: grid;
        grid-template-columns: 3fr 4.5fr 2fr;
        grid-column-gap: 1.36rem;
        -webkit-column-gap: 1.36rem;
                column-gap: 1.36rem;
        margin-top: 1.63rem; }
        @media (max-width: 991px) {
          .dashboard-listing-wrapper .dashboard-listing-content .content .filters {
            display: block; } }
        .dashboard-listing-wrapper .dashboard-listing-content .content .filters .wrapper-select {
          display: grid;
          grid-template-columns: 2fr 3fr;
          grid-column-gap: 1.36rem;
          -webkit-column-gap: 1.36rem;
                  column-gap: 1.36rem; }
          @media (max-width: 991px) {
            .dashboard-listing-wrapper .dashboard-listing-content .content .filters .wrapper-select {
              margin-top: 1.05rem;
              margin-bottom: 1.05rem;
              grid-template-columns: 2.5fr 2.5fr; } }
          @media (max-width: 767px) {
            .dashboard-listing-wrapper .dashboard-listing-content .content .filters .wrapper-select {
              display: block; } }
        .dashboard-listing-wrapper .dashboard-listing-content .content .filters .search {
          height: 2.73rem;
          border: 1px solid rgba(1, 1, 1, 0.2);
          box-sizing: border-box;
          box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
          border-radius: 10px;
          -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
                  transform: matrix(1, 0, 0, 1, 0, 0);
          display: flex;
          padding: 0.89rem 1.05rem;
          align-items: center; }
          .dashboard-listing-wrapper .dashboard-listing-content .content .filters .search svg {
            margin-right: 0.73rem; }
          .dashboard-listing-wrapper .dashboard-listing-content .content .filters .search input {
            border: 0; }
            .dashboard-listing-wrapper .dashboard-listing-content .content .filters .search input::-webkit-input-placeholder {
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 0.88rem;
              line-height: 1.05rem;
              letter-spacing: 0.1px;
              color: #010101;
              opacity: 0.6; }
            .dashboard-listing-wrapper .dashboard-listing-content .content .filters .search input::placeholder {
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 0.88rem;
              line-height: 1.05rem;
              letter-spacing: 0.1px;
              color: #010101;
              opacity: 0.6; }
        .dashboard-listing-wrapper .dashboard-listing-content .content .filters .create-new {
          height: 2.73rem;
          background: #3176bc;
          box-shadow: 0px 4px 16px rgba(109, 141, 173, 0.03);
          border-radius: 10px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.88rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #ffffff; }

.disabled {
  pointer-events: none;
  opacity: 0.5; }

.listing-dashboard-wrapper {
  display: grid;
  grid-template-columns: 2fr 5fr;
  margin-top: 2.63rem; }
  @media (max-width: 991px) {
    .listing-dashboard-wrapper {
      display: block;
      text-align: center; } }
  .listing-dashboard-wrapper .left {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr; }
    @media (max-width: 991px) {
      .listing-dashboard-wrapper .left {
        display: block; } }
    .listing-dashboard-wrapper .left > div {
      margin-right: 1.1rem; }
      .listing-dashboard-wrapper .left > div a {
        text-decoration: unset; }
      .listing-dashboard-wrapper .left > div img {
        width: 100%;
        max-height: 8.47rem;
        object-fit: cover;
        border-radius: 10px; }
        @media (max-width: 991px) {
          .listing-dashboard-wrapper .left > div img {
            max-width: 11rem;
            margin-bottom: 1rem; } }
    .listing-dashboard-wrapper .left .list {
      padding-top: 1.31rem;
      background-color: #3176bc;
      color: #ffffff;
      box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
      border-radius: 10px; }
      .listing-dashboard-wrapper .left .list #leads,
      .listing-dashboard-wrapper .left .list #score {
        display: none; }
      @media (max-width: 991px) {
        .listing-dashboard-wrapper .left .list {
          float: left;
          width: 20%;
          margin-bottom: 2rem; } }
      @media (max-width: 767px) {
        .listing-dashboard-wrapper .left .list {
          width: 48%; }
          .listing-dashboard-wrapper .left .list #leads,
          .listing-dashboard-wrapper .left .list #score {
            display: block; } }
      @media (max-width: 450px) {
        .listing-dashboard-wrapper .left .list {
          width: 45%; } }
      .listing-dashboard-wrapper .left .list h3 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 0.73rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        padding-left: 1.63rem;
        padding-right: 1.89rem;
        padding-top: 0.52rem;
        padding-bottom: 0.52rem;
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .listing-dashboard-wrapper .right {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
    border-radius: 10px;
    padding-top: 1.36rem;
    overflow: hidden; }
    @media (max-width: 991px) {
      .listing-dashboard-wrapper .right {
        float: right;
        width: 77%; } }
    @media (max-width: 767px) {
      .listing-dashboard-wrapper .right {
        width: 48%;
        text-align: left; } }
    @media (max-width: 450px) {
      .listing-dashboard-wrapper .right {
        width: 45%; } }
    .listing-dashboard-wrapper .right .toggle-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .listing-dashboard-wrapper .right .toggle-wrapper .custom-toggle-button {
        display: flex;
        align-items: center; }
        .listing-dashboard-wrapper .right .toggle-wrapper .custom-toggle-button .switch {
          width: 3.05rem;
          height: 1.5rem; }
          .listing-dashboard-wrapper .right .toggle-wrapper .custom-toggle-button .switch .slider p {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.68rem;
            line-height: 1.1rem;
            letter-spacing: 0.1px;
            color: #ffffff;
            margin: 0;
            position: absolute;
            left: 0.5rem;
            bottom: 0.15rem; }
          .listing-dashboard-wrapper .right .toggle-wrapper .custom-toggle-button .switch .slider:before {
            height: 1.28rem;
            width: 1.28rem;
            left: 0.18rem;
            bottom: 0.12 0.5rem; }
          .listing-dashboard-wrapper .right .toggle-wrapper .custom-toggle-button .switch input:checked + .slider:before {
            -webkit-transform: translateX(1.45rem);
            transform: translateX(1.45rem); }
    .listing-dashboard-wrapper .right > div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.52rem;
      padding-left: 1.15rem;
      padding-right: 1.15rem;
      padding-top: 0.52rem;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      overflow: hidden;
      white-space: nowrap; }
    .listing-dashboard-wrapper .right .info {
      display: flex;
      justify-content: space-between;
      padding: 1.15rem 1.15rem;
      padding-top: unset;
      padding-bottom: unset;
      align-items: center; }
      @media (max-width: 767px) {
        .listing-dashboard-wrapper .right .info {
          display: block;
          padding-left: 0;
          padding-right: 0; } }
      .listing-dashboard-wrapper .right .info h3 {
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 0.88rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.6; }
        @media (max-width: 767px) {
          .listing-dashboard-wrapper .right .info h3 {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 0.52rem;
            padding-top: 0.52rem;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            opacity: 0.8;
            padding-left: 1rem; } }
      .listing-dashboard-wrapper .right .info .leads,
      .listing-dashboard-wrapper .right .info .score {
        display: flex;
        align-items: center; }
        @media (max-width: 767px) {
          .listing-dashboard-wrapper .right .info .leads,
          .listing-dashboard-wrapper .right .info .score {
            display: block; } }
        .listing-dashboard-wrapper .right .info .leads .blue,
        .listing-dashboard-wrapper .right .info .score .blue {
          background-color: #3176bc;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 0.88rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #ffffff;
          padding: 0.52rem 1.15rem;
          margin-right: 1.68rem; }
          @media (max-width: 767px) {
            .listing-dashboard-wrapper .right .info .leads .blue,
            .listing-dashboard-wrapper .right .info .score .blue {
              display: none; } }
    .listing-dashboard-wrapper .right .price {
      font-weight: 700;
      color: #3176bc;
      opacity: 0.6; }
  .listing-dashboard-wrapper button {
    box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
    border-radius: 5px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-top: 0.42rem; }
  .listing-dashboard-wrapper .buttons {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    @media (max-width: 991px) {
      .listing-dashboard-wrapper .buttons {
        clear: both; } }
    @media (max-width: 767px) {
      .listing-dashboard-wrapper .buttons {
        margin-top: 2rem;
        display: block; } }
    .listing-dashboard-wrapper .buttons a {
      text-decoration: unset;
      margin-right: 0.42rem; }
      .listing-dashboard-wrapper .buttons a button {
        background-color: #3176bc !important; }
    .listing-dashboard-wrapper .buttons button {
      padding-top: 0.31rem;
      padding-bottom: 0.31rem;
      text-align: center; }
      .listing-dashboard-wrapper .buttons button i {
        margin-right: 0.5rem; }
      .listing-dashboard-wrapper .buttons button:last-child {
        background: #d0342c; }
      .listing-dashboard-wrapper .buttons button:disabled {
        opacity: 0.5; }
  .listing-dashboard-wrapper button {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #3176bc -3.43%, #4ebbb8 104.41%);
    padding-top: 0.52rem !important;
    padding-bottom: 0.52rem !important; }
    .listing-dashboard-wrapper button svg {
      margin-right: 0.47rem; }

.select-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #3176bc;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
  height: 2.73rem; }
  .select-input-wrapper svg:first-child {
    margin-left: 0.5rem; }
  @media (max-width: 767px) {
    .select-input-wrapper {
      margin-top: 1.05rem;
      margin-bottom: 1.05rem; } }
  .select-input-wrapper h6 {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.86rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8;
    padding: 0.78rem 1.36rem; }
  .select-input-wrapper .result {
    top: 2.8rem;
    position: absolute;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    width: 100%;
    background-color: #ffffff;
    z-index: 99;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-height: 520px;
    overflow: hidden;
    overflow-y: auto; }
    .select-input-wrapper .result::-webkit-scrollbar {
      width: 10px; }
    .select-input-wrapper .result::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey; }
    .select-input-wrapper .result::-webkit-scrollbar-thumb {
      background: #3176bc;
      border-radius: 10px; }
    .select-input-wrapper .result::-webkit-scrollbar-thumb:hover {
      background: #3176bc; }
    .select-input-wrapper .result h6 {
      white-space: nowrap; }
      .select-input-wrapper .result h6:hover {
        background-color: #3176bc; }

.modal-background {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  height: 100vh; }
  .modal-background .modal-create-new-listing-wrapper {
    background-color: #ffffff;
    width: 32.15rem;
    border-radius: 10px;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
            transform: translate(-50%, -30%);
    padding-bottom: 2.15rem;
    padding-left: 1.89rem;
    padding-right: 1.89rem;
    padding-top: 3.31rem; }
    .modal-background .modal-create-new-listing-wrapper .heading-import {
      text-align: center; }
      .modal-background .modal-create-new-listing-wrapper .heading-import h1 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.05rem;
        line-height: 1.10rem;
        letter-spacing: 0.1px;
        color: #010101; }
      .modal-background .modal-create-new-listing-wrapper .heading-import input[type=text] {
        margin-top: 1.10rem;
        background: #ffffff;
        border: 1px solid rgba(1, 1, 1, 0.2);
        border-radius: 5px;
        -webkit-transform: rotate(-0.12deg);
                transform: rotate(-0.12deg); }
      .modal-background .modal-create-new-listing-wrapper .heading-import .terms {
        display: flex;
        align-items: center;
        text-align: start; }
        .modal-background .modal-create-new-listing-wrapper .heading-import .terms .checkbox-wrapper {
          margin-right: 0.42rem; }
          .modal-background .modal-create-new-listing-wrapper .heading-import .terms .checkbox-wrapper .checkbox {
            width: 1.05rem;
            height: 1.05rem;
            background: #ffffff;
            border: 1px solid #010101;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center; }
            .modal-background .modal-create-new-listing-wrapper .heading-import .terms .checkbox-wrapper .checkbox svg {
              width: 78%;
              height: 100%; }
        .modal-background .modal-create-new-listing-wrapper .heading-import .terms h5 {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.73rem;
          line-height: 1.10rem;
          letter-spacing: 0.1px;
          color: #010101; }
          .modal-background .modal-create-new-listing-wrapper .heading-import .terms h5 a {
            color: #3176bc;
            font-weight: 600; }
      .modal-background .modal-create-new-listing-wrapper .heading-import button {
        margin-top: 1.26rem; }
    .modal-background .modal-create-new-listing-wrapper .row {
      margin-top: 1.26rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      justify-content: center;
      text-align: center;
      display: flex; }
    @media (max-width: 767px) {
      .modal-background .modal-create-new-listing-wrapper {
        width: 80%; }
        .modal-background .modal-create-new-listing-wrapper .row {
          display: block; } }
    .modal-background .modal-create-new-listing-wrapper .content {
      text-align: center; }
      .modal-background .modal-create-new-listing-wrapper .content p {
        margin-bottom: 1.68rem; }
      .modal-background .modal-create-new-listing-wrapper .content .header {
        left: 1rem;
        right: unset; }
    .modal-background .modal-create-new-listing-wrapper .last-modal {
      text-align: start; }
      .modal-background .modal-create-new-listing-wrapper .last-modal p {
        text-align: start; }
      .modal-background .modal-create-new-listing-wrapper .last-modal .button-wrapper {
        width: 100%;
        text-align: center; }
      .modal-background .modal-create-new-listing-wrapper .last-modal .select-input-wrapper .result h6 {
        margin: 0; }
    .modal-background .modal-create-new-listing-wrapper .header {
      position: absolute;
      top: 1rem;
      right: 0.5rem; }
      .modal-background .modal-create-new-listing-wrapper .header button {
        all: unset;
        border: 0;
        background-color: #ffffff; }
    .modal-background .modal-create-new-listing-wrapper .left {
      padding-right: 2.1rem;
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
      @media (max-width: 767px) {
        .modal-background .modal-create-new-listing-wrapper .left {
          padding-right: unset;
          border: 0;
          padding-bottom: 1rem; } }
    .modal-background .modal-create-new-listing-wrapper .right {
      padding-left: 2.1rem; }
      @media (max-width: 767px) {
        .modal-background .modal-create-new-listing-wrapper .right {
          padding-left: unset;
          margin-top: 1rem;
          border-top: 1px solid rgba(0, 0, 0, 0.1); } }
    .modal-background .modal-create-new-listing-wrapper .left,
    .modal-background .modal-create-new-listing-wrapper .right {
      text-align: center;
      max-width: 13.68rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.57rem; }
      @media (max-width: 767px) {
        .modal-background .modal-create-new-listing-wrapper .left,
        .modal-background .modal-create-new-listing-wrapper .right {
          max-width: 100%; } }
    .modal-background .modal-create-new-listing-wrapper h6 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0;
      margin-top: 1.42rem;
      margin-bottom: 1rem; }
    .modal-background .modal-create-new-listing-wrapper p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.73rem;
      line-height: 1.1rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.6;
      margin: 0; }
      .modal-background .modal-create-new-listing-wrapper p a {
        color: #3176bc; }
    .modal-background .modal-create-new-listing-wrapper h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.05rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #3176bc; }
    .modal-background .modal-create-new-listing-wrapper input {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.84rem;
      box-sizing: border-box; }
    .modal-background .modal-create-new-listing-wrapper button {
      box-shadow: 0px 0px 12px rgba(49, 118, 188, 0.25);
      border-radius: 10px;
      background-color: #3176bc;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.73rem;
      line-height: 1.1rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
      border: 0;
      padding: 1rem 2rem 1rem 2rem;
      margin-top: 2.15rem; }

.faq-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.18);
  border-radius: 15px;
  padding: 2.15rem 2.63rem 2.52rem 2.52rem;
  margin-bottom: 1.25rem; }
  @media (max-width: 767px) {
    .faq-wrapper {
      padding: 2.15rem 1.63rem 1.8rem 1.52rem; } }
  .faq-wrapper .question > div {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .faq-wrapper .question > div h1 {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 1.05rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin: 0; }
    .faq-wrapper .question > div button {
      background-color: #ffffff;
      border: 0; }
    .faq-wrapper .question > div .answer p {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.88rem;
      line-height: 1.47rem;
      letter-spacing: 0.3px;
      color: #010101;
      opacity: 0.7;
      margin: 0; }
      @media (max-width: 767px) {
        .faq-wrapper .question > div .answer p {
          margin-top: 5rem; } }

.package-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
  border-radius: 5px;
  padding: 1.26rem 0.84rem;
  max-width: 15.47rem; }
  @media (max-width: 991px) {
    .package-wrapper {
      width: 100%;
      max-width: unset; } }
  .package-wrapper .plan {
    display: flex;
    background: #cccccc;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #bfbcbc;
    margin-bottom: 1.5rem; }
    @media (max-width: 767px) {
      .package-wrapper .plan {
        width: 100%; } }
    .package-wrapper .plan button {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.77rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      padding: 0.73rem 1rem;
      display: flex;
      align-items: center;
      border: 0;
      background-color: transparent; }
      .package-wrapper .plan button.active {
        background: #3176bc;
        border: 3px solid #26ade3; }
      @media (max-width: 767px) {
        .package-wrapper .plan button {
          width: 50%;
          justify-content: center; } }
  .package-wrapper .logo {
    margin-bottom: 1rem; }
  .package-wrapper .title {
    font-family: "Qartella";
    font-style: normal;
    font-weight: 800;
    font-size: 0.94rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8; }
  .package-wrapper .desc {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.6; }
  .package-wrapper .price {
    display: flex;
    align-items: center; }
    .package-wrapper .price h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 1.89rem;
      line-height: 2.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin-right: 5px; }
    .package-wrapper .price span {
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.78rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8; }
  .package-wrapper .subscribe-link button {
    background: #3176bc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding: 0.52rem;
    width: 100%;
    border: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem !important;
    line-height: 1.1rem !important;
    letter-spacing: 0.1px;
    color: #ffffff !important;
    margin-bottom: 0.84rem;
    text-decoration: none;
    display: block;
    text-align: center; }
    @media (max-width: 991px) {
      .package-wrapper .subscribe-link button {
        width: 40%; } }
    @media (max-width: 767px) {
      .package-wrapper .subscribe-link button {
        width: 100%; } }
    .package-wrapper .subscribe-link button.active {
      background-color: #010101; }
    .package-wrapper .subscribe-link button:link {
      color: #ffffff; }
  .package-wrapper .includes .title-includes {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.84rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8; }
  .package-wrapper .includes .dot {
    display: flex;
    align-items: flex-start; }
    .package-wrapper .includes .dot svg {
      opacity: 0.6;
      flex-shrink: 0;
      width: 16px; }
    .package-wrapper .includes .dot h6 {
      font-family: "Poppins", sans-serif;
      font-size: 0.73rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.6;
      margin: 0;
      margin-top: 0.5rem;
      margin-left: 0.57rem;
      font-weight: 400; }

.subscription-wrapper {
  width: 100%; }
  .subscription-wrapper .title {
    font-family: "Qartella", sans-serif;
    font-weight: 800;
    font-size: 1.05rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8;
    margin-bottom: 1.1rem; }
  .subscription-wrapper .buttons {
    display: flex;
    grid-column-gap: 0.6rem;
    -webkit-column-gap: 0.6rem;
            column-gap: 0.6rem;
    margin-bottom: 1rem; }
    .subscription-wrapper .buttons button {
      background: #cccccc;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.87rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      border: 0;
      padding: 0.73rem 0.52rem;
      display: flex;
      align-items: center; }
      .subscription-wrapper .buttons button:last-child {
        background-color: transparent;
        color: #010101;
        border: 1px solid #010101; }
      .subscription-wrapper .buttons button svg {
        margin-right: 0.4rem; }
      .subscription-wrapper .buttons button.active {
        background: #3176bc; }
      .subscription-wrapper .buttons button.subscribed {
        background-color: #00AB66; }
  .subscription-wrapper .box {
    background: #ffffff;
    border: 1px solid rgba(218, 218, 218, 0.6);
    box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
    width: 100%;
    margin-bottom: 2.63rem;
    display: flex;
    padding: 1.57rem;
    grid-column-gap: 0.78rem;
    -webkit-column-gap: 0.78rem;
            column-gap: 0.78rem;
    align-items: baseline;
    box-sizing: border-box; }
    .subscription-wrapper .box *,
    .subscription-wrapper .box *:before,
    .subscription-wrapper .box *:after {
      box-sizing: inherit; }
    @media (max-width: 991px) {
      .subscription-wrapper .box {
        flex-direction: column;
        grid-row-gap: 0.78rem;
        row-gap: 0.78rem; } }
  .subscription-wrapper .info {
    display: flex;
    align-items: center;
    grid-column-gap: 0.4rem;
    -webkit-column-gap: 0.4rem;
            column-gap: 0.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.6; }

.account-settings-wrapper, .listing-statistic-wrapper {
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
  margin: 0.97rem 3.78rem 1.15rem 0.42rem; }
  @media (max-width: 991px) {
    .account-settings-wrapper, .listing-statistic-wrapper {
      margin: 0.97rem 1.68rem 3.15rem 1.68rem; } }
  @media (max-width: 767px) {
    .account-settings-wrapper, .listing-statistic-wrapper {
      margin: 0.97rem 0.72rem 3.15rem 0.42rem; } }
  .account-settings-wrapper:first-child, .listing-statistic-wrapper:first-child {
    margin: 0.97rem 3.78rem 1.15rem 0.42rem; }
    @media (max-width: 991px) {
      .account-settings-wrapper:first-child, .listing-statistic-wrapper:first-child {
        margin: 0.97rem 1.68rem 3.15rem 1.68rem; } }
    @media (max-width: 767px) {
      .account-settings-wrapper:first-child, .listing-statistic-wrapper:first-child {
        margin: 0.97rem 0.72rem 3.15rem 0.42rem; } }
  .account-settings-wrapper .content, .listing-statistic-wrapper .content {
    padding: 2.63rem 1.15rem 2rem 4.36rem; }
    @media (max-width: 991px) {
      .account-settings-wrapper .content, .listing-statistic-wrapper .content {
        padding: 2.63rem 1.68rem 2rem 1.68rem; } }
    @media (max-width: 767px) {
      .account-settings-wrapper .content, .listing-statistic-wrapper .content {
        padding: 2.63rem 0.84rem 2rem 0.84rem; } }
    .account-settings-wrapper .content h1, .listing-statistic-wrapper .content h1 {
      font-family: "Qartella", sans-serif;
      font-weight: 400;
      font-size: 1.52rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0; }
    .account-settings-wrapper .content h4, .listing-statistic-wrapper .content h4 {
      font-family: "Qartella", sans-serif;
      font-weight: 800;
      font-size: 1.05rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin: 0; }
    .account-settings-wrapper .content .contact, .listing-statistic-wrapper .content .contact {
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .account-settings-wrapper .content .contact, .listing-statistic-wrapper .content .contact {
          display: block; } }
      .account-settings-wrapper .content .contact p, .listing-statistic-wrapper .content .contact p {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.73rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.6;
        margin: 0;
        margin-right: 1.21rem; }
        @media (max-width: 767px) {
          .account-settings-wrapper .content .contact p, .listing-statistic-wrapper .content .contact p {
            margin-bottom: 1rem; } }
      .account-settings-wrapper .content .contact a, .listing-statistic-wrapper .content .contact a {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 0.73rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #3176bc; }
    .account-settings-wrapper .content .member, .listing-statistic-wrapper .content .member {
      margin-top: 1.89rem; }
      .account-settings-wrapper .content .member h2, .listing-statistic-wrapper .content .member h2 {
        font-family: "Qartella", sans-serif;
        font-weight: 800;
        font-size: 1.05rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        margin: 0; }
      .account-settings-wrapper .content .member h3, .listing-statistic-wrapper .content .member h3 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 0.73rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.6; }
  .account-settings-wrapper .update-password:first-child .input,
  .account-settings-wrapper .company-name:first-child .input,
  .account-settings-wrapper .contact-info:first-child .input,
  .account-settings-wrapper .branding:first-child .input, .listing-statistic-wrapper .update-password:first-child .input,
  .listing-statistic-wrapper .company-name:first-child .input,
  .listing-statistic-wrapper .contact-info:first-child .input,
  .listing-statistic-wrapper .branding:first-child .input {
    margin-top: 2.47rem; }
  .account-settings-wrapper .update-password .dropzone-wrapper,
  .account-settings-wrapper .company-name .dropzone-wrapper,
  .account-settings-wrapper .contact-info .dropzone-wrapper,
  .account-settings-wrapper .branding .dropzone-wrapper, .listing-statistic-wrapper .update-password .dropzone-wrapper,
  .listing-statistic-wrapper .company-name .dropzone-wrapper,
  .listing-statistic-wrapper .contact-info .dropzone-wrapper,
  .listing-statistic-wrapper .branding .dropzone-wrapper {
    margin-top: 1rem; }
  .account-settings-wrapper .update-password button,
  .account-settings-wrapper .company-name button,
  .account-settings-wrapper .contact-info button,
  .account-settings-wrapper .branding button, .listing-statistic-wrapper .update-password button,
  .listing-statistic-wrapper .company-name button,
  .listing-statistic-wrapper .contact-info button,
  .listing-statistic-wrapper .branding button {
    background-color: #3176bc;
    border: 0;
    box-shadow: 0px 0px 16px rgba(109, 141, 173, 0.25), inset 0px 4px 12px rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.01rem;
    letter-spacing: 1px;
    color: #ffffff;
    padding: 1.26rem 2.78rem;
    margin-top: 1.68rem; }
    .account-settings-wrapper .update-password button:disabled,
    .account-settings-wrapper .company-name button:disabled,
    .account-settings-wrapper .contact-info button:disabled,
    .account-settings-wrapper .branding button:disabled, .listing-statistic-wrapper .update-password button:disabled,
    .listing-statistic-wrapper .company-name button:disabled,
    .listing-statistic-wrapper .contact-info button:disabled,
    .listing-statistic-wrapper .branding button:disabled {
      background: rgba(1, 1, 1, 0.1);
      box-shadow: unset;
      color: rgba(0, 0, 0, 0.5); }
  .account-settings-wrapper .update-password .input,
  .account-settings-wrapper .company-name .input,
  .account-settings-wrapper .contact-info .input,
  .account-settings-wrapper .branding .input, .listing-statistic-wrapper .update-password .input,
  .listing-statistic-wrapper .company-name .input,
  .listing-statistic-wrapper .contact-info .input,
  .listing-statistic-wrapper .branding .input {
    max-width: 32.42rem;
    margin-top: 1.26rem; }
    @media (max-width: 767px) {
      .account-settings-wrapper .update-password .input,
      .account-settings-wrapper .company-name .input,
      .account-settings-wrapper .contact-info .input,
      .account-settings-wrapper .branding .input, .listing-statistic-wrapper .update-password .input,
      .listing-statistic-wrapper .company-name .input,
      .listing-statistic-wrapper .contact-info .input,
      .listing-statistic-wrapper .branding .input {
        max-width: 100%; } }
    .account-settings-wrapper .update-password .input .label-wrapper,
    .account-settings-wrapper .company-name .input .label-wrapper,
    .account-settings-wrapper .contact-info .input .label-wrapper,
    .account-settings-wrapper .branding .input .label-wrapper, .listing-statistic-wrapper .update-password .input .label-wrapper,
    .listing-statistic-wrapper .company-name .input .label-wrapper,
    .listing-statistic-wrapper .contact-info .input .label-wrapper,
    .listing-statistic-wrapper .branding .input .label-wrapper {
      display: flex;
      justify-content: space-between; }
      .account-settings-wrapper .update-password .input .label-wrapper a,
      .account-settings-wrapper .company-name .input .label-wrapper a,
      .account-settings-wrapper .contact-info .input .label-wrapper a,
      .account-settings-wrapper .branding .input .label-wrapper a, .listing-statistic-wrapper .update-password .input .label-wrapper a,
      .listing-statistic-wrapper .company-name .input .label-wrapper a,
      .listing-statistic-wrapper .contact-info .input .label-wrapper a,
      .listing-statistic-wrapper .branding .input .label-wrapper a {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        line-height: 1.1rem;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #3176bc; }
    .account-settings-wrapper .update-password .input label,
    .account-settings-wrapper .company-name .input label,
    .account-settings-wrapper .contact-info .input label,
    .account-settings-wrapper .branding .input label, .listing-statistic-wrapper .update-password .input label,
    .listing-statistic-wrapper .company-name .input label,
    .listing-statistic-wrapper .contact-info .input label,
    .listing-statistic-wrapper .branding .input label {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.6;
      display: flex;
      align-items: center; }
      .account-settings-wrapper .update-password .input label svg,
      .account-settings-wrapper .company-name .input label svg,
      .account-settings-wrapper .contact-info .input label svg,
      .account-settings-wrapper .branding .input label svg, .listing-statistic-wrapper .update-password .input label svg,
      .listing-statistic-wrapper .company-name .input label svg,
      .listing-statistic-wrapper .contact-info .input label svg,
      .listing-statistic-wrapper .branding .input label svg {
        margin-left: 0.57rem; }
    .account-settings-wrapper .update-password .input input,
    .account-settings-wrapper .update-password .input textarea,
    .account-settings-wrapper .company-name .input input,
    .account-settings-wrapper .company-name .input textarea,
    .account-settings-wrapper .contact-info .input input,
    .account-settings-wrapper .contact-info .input textarea,
    .account-settings-wrapper .branding .input input,
    .account-settings-wrapper .branding .input textarea, .listing-statistic-wrapper .update-password .input input,
    .listing-statistic-wrapper .update-password .input textarea,
    .listing-statistic-wrapper .company-name .input input,
    .listing-statistic-wrapper .company-name .input textarea,
    .listing-statistic-wrapper .contact-info .input input,
    .listing-statistic-wrapper .contact-info .input textarea,
    .listing-statistic-wrapper .branding .input input,
    .listing-statistic-wrapper .branding .input textarea {
      margin-top: 0.84rem;
      background: #ffffff;
      border: 1px solid rgba(1, 1, 1, 0.05);
      box-sizing: border-box;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      height: 2.73rem;
      font-size: 0.88rem;
      line-height: 1.1rem;
      padding-left: 1rem; }
      .account-settings-wrapper .update-password .input input:disabled,
      .account-settings-wrapper .update-password .input textarea:disabled,
      .account-settings-wrapper .company-name .input input:disabled,
      .account-settings-wrapper .company-name .input textarea:disabled,
      .account-settings-wrapper .contact-info .input input:disabled,
      .account-settings-wrapper .contact-info .input textarea:disabled,
      .account-settings-wrapper .branding .input input:disabled,
      .account-settings-wrapper .branding .input textarea:disabled, .listing-statistic-wrapper .update-password .input input:disabled,
      .listing-statistic-wrapper .update-password .input textarea:disabled,
      .listing-statistic-wrapper .company-name .input input:disabled,
      .listing-statistic-wrapper .company-name .input textarea:disabled,
      .listing-statistic-wrapper .contact-info .input input:disabled,
      .listing-statistic-wrapper .contact-info .input textarea:disabled,
      .listing-statistic-wrapper .branding .input input:disabled,
      .listing-statistic-wrapper .branding .input textarea:disabled {
        background: rgba(1, 1, 1, 0.05); }
    .account-settings-wrapper .update-password .input textarea,
    .account-settings-wrapper .company-name .input textarea,
    .account-settings-wrapper .contact-info .input textarea,
    .account-settings-wrapper .branding .input textarea, .listing-statistic-wrapper .update-password .input textarea,
    .listing-statistic-wrapper .company-name .input textarea,
    .listing-statistic-wrapper .contact-info .input textarea,
    .listing-statistic-wrapper .branding .input textarea {
      height: unset;
      min-height: 13.36rem; }
  .account-settings-wrapper .update-password .black-button,
  .account-settings-wrapper .company-name .black-button,
  .account-settings-wrapper .contact-info .black-button,
  .account-settings-wrapper .branding .black-button, .listing-statistic-wrapper .update-password .black-button,
  .listing-statistic-wrapper .company-name .black-button,
  .listing-statistic-wrapper .contact-info .black-button,
  .listing-statistic-wrapper .branding .black-button {
    background-color: #010101;
    padding: 0.89rem 1.31rem 0.89rem 1.31rem;
    margin-top: 0; }
  .account-settings-wrapper .update-password .company-description,
  .account-settings-wrapper .update-password .company-address,
  .account-settings-wrapper .update-password .edit-company-info,
  .account-settings-wrapper .company-name .company-description,
  .account-settings-wrapper .company-name .company-address,
  .account-settings-wrapper .company-name .edit-company-info,
  .account-settings-wrapper .contact-info .company-description,
  .account-settings-wrapper .contact-info .company-address,
  .account-settings-wrapper .contact-info .edit-company-info,
  .account-settings-wrapper .branding .company-description,
  .account-settings-wrapper .branding .company-address,
  .account-settings-wrapper .branding .edit-company-info, .listing-statistic-wrapper .update-password .company-description,
  .listing-statistic-wrapper .update-password .company-address,
  .listing-statistic-wrapper .update-password .edit-company-info,
  .listing-statistic-wrapper .company-name .company-description,
  .listing-statistic-wrapper .company-name .company-address,
  .listing-statistic-wrapper .company-name .edit-company-info,
  .listing-statistic-wrapper .contact-info .company-description,
  .listing-statistic-wrapper .contact-info .company-address,
  .listing-statistic-wrapper .contact-info .edit-company-info,
  .listing-statistic-wrapper .branding .company-description,
  .listing-statistic-wrapper .branding .company-address,
  .listing-statistic-wrapper .branding .edit-company-info {
    margin-top: 3.42rem; }
    .account-settings-wrapper .update-password .company-description .label, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper,
    .account-settings-wrapper .update-password .company-address .label,
    .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper,
    .account-settings-wrapper .update-password .edit-company-info .label,
    .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper,
    .account-settings-wrapper .company-name .company-description .label,
    .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper,
    .account-settings-wrapper .company-name .company-address .label,
    .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper,
    .account-settings-wrapper .company-name .edit-company-info .label,
    .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper,
    .account-settings-wrapper .contact-info .company-description .label,
    .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper,
    .account-settings-wrapper .contact-info .company-address .label,
    .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper,
    .account-settings-wrapper .contact-info .edit-company-info .label,
    .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper,
    .account-settings-wrapper .branding .company-description .label,
    .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper,
    .account-settings-wrapper .branding .company-address .label,
    .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper,
    .account-settings-wrapper .branding .edit-company-info .label,
    .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper, .listing-statistic-wrapper .update-password .company-description .label, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper,
    .listing-statistic-wrapper .update-password .company-address .label,
    .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper,
    .listing-statistic-wrapper .update-password .edit-company-info .label,
    .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper,
    .listing-statistic-wrapper .company-name .company-description .label,
    .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper,
    .listing-statistic-wrapper .company-name .company-address .label,
    .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper,
    .listing-statistic-wrapper .company-name .edit-company-info .label,
    .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper,
    .listing-statistic-wrapper .contact-info .company-description .label,
    .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper,
    .listing-statistic-wrapper .contact-info .company-address .label,
    .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper,
    .listing-statistic-wrapper .contact-info .edit-company-info .label,
    .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper,
    .listing-statistic-wrapper .branding .company-description .label,
    .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper,
    .listing-statistic-wrapper .branding .company-address .label,
    .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper,
    .listing-statistic-wrapper .branding .edit-company-info .label,
    .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
    .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 500px) {
        .account-settings-wrapper .update-password .company-description .label, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper,
        .account-settings-wrapper .update-password .company-address .label,
        .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper,
        .account-settings-wrapper .update-password .edit-company-info .label,
        .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper,
        .account-settings-wrapper .company-name .company-description .label,
        .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper,
        .account-settings-wrapper .company-name .company-address .label,
        .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper,
        .account-settings-wrapper .company-name .edit-company-info .label,
        .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper,
        .account-settings-wrapper .contact-info .company-description .label,
        .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper,
        .account-settings-wrapper .contact-info .company-address .label,
        .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper,
        .account-settings-wrapper .contact-info .edit-company-info .label,
        .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper,
        .account-settings-wrapper .branding .company-description .label,
        .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper,
        .account-settings-wrapper .branding .company-address .label,
        .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper,
        .account-settings-wrapper .branding .edit-company-info .label,
        .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper, .listing-statistic-wrapper .update-password .company-description .label, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper,
        .listing-statistic-wrapper .update-password .company-address .label,
        .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper,
        .listing-statistic-wrapper .update-password .edit-company-info .label,
        .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper,
        .listing-statistic-wrapper .company-name .company-description .label,
        .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper,
        .listing-statistic-wrapper .company-name .company-address .label,
        .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper,
        .listing-statistic-wrapper .company-name .edit-company-info .label,
        .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper,
        .listing-statistic-wrapper .contact-info .company-description .label,
        .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper,
        .listing-statistic-wrapper .contact-info .company-address .label,
        .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper,
        .listing-statistic-wrapper .contact-info .edit-company-info .label,
        .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper,
        .listing-statistic-wrapper .branding .company-description .label,
        .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper,
        .listing-statistic-wrapper .branding .company-address .label,
        .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper,
        .listing-statistic-wrapper .branding .edit-company-info .label,
        .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper {
          display: block; } }
      .account-settings-wrapper .update-password .company-description .label label, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper label,
      .account-settings-wrapper .update-password .company-address .label label,
      .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper label,
      .account-settings-wrapper .update-password .edit-company-info .label label,
      .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper label,
      .account-settings-wrapper .company-name .company-description .label label,
      .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper label,
      .account-settings-wrapper .company-name .company-address .label label,
      .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper label,
      .account-settings-wrapper .company-name .edit-company-info .label label,
      .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper label,
      .account-settings-wrapper .contact-info .company-description .label label,
      .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper label,
      .account-settings-wrapper .contact-info .company-address .label label,
      .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper label,
      .account-settings-wrapper .contact-info .edit-company-info .label label,
      .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper label,
      .account-settings-wrapper .branding .company-description .label label,
      .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper label,
      .account-settings-wrapper .branding .company-address .label label,
      .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper label,
      .account-settings-wrapper .branding .edit-company-info .label label,
      .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper label, .listing-statistic-wrapper .update-password .company-description .label label, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper label,
      .listing-statistic-wrapper .update-password .company-address .label label,
      .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper label,
      .listing-statistic-wrapper .update-password .edit-company-info .label label,
      .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper label,
      .listing-statistic-wrapper .company-name .company-description .label label,
      .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper label,
      .listing-statistic-wrapper .company-name .company-address .label label,
      .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper label,
      .listing-statistic-wrapper .company-name .edit-company-info .label label,
      .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper label,
      .listing-statistic-wrapper .contact-info .company-description .label label,
      .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper label,
      .listing-statistic-wrapper .contact-info .company-address .label label,
      .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper label,
      .listing-statistic-wrapper .contact-info .edit-company-info .label label,
      .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper label,
      .listing-statistic-wrapper .branding .company-description .label label,
      .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper label,
      .listing-statistic-wrapper .branding .company-address .label label,
      .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper label,
      .listing-statistic-wrapper .branding .edit-company-info .label label,
      .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper label {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.6;
        display: flex;
        align-items: center; }
        .account-settings-wrapper .update-password .company-description .label label svg, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper label svg,
        .account-settings-wrapper .update-password .company-address .label label svg,
        .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper label svg,
        .account-settings-wrapper .update-password .edit-company-info .label label svg,
        .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper label svg,
        .account-settings-wrapper .company-name .company-description .label label svg,
        .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper label svg,
        .account-settings-wrapper .company-name .company-address .label label svg,
        .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper label svg,
        .account-settings-wrapper .company-name .edit-company-info .label label svg,
        .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper label svg,
        .account-settings-wrapper .contact-info .company-description .label label svg,
        .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper label svg,
        .account-settings-wrapper .contact-info .company-address .label label svg,
        .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper label svg,
        .account-settings-wrapper .contact-info .edit-company-info .label label svg,
        .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper label svg,
        .account-settings-wrapper .branding .company-description .label label svg,
        .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper label svg,
        .account-settings-wrapper .branding .company-address .label label svg,
        .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper label svg,
        .account-settings-wrapper .branding .edit-company-info .label label svg,
        .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper label svg, .listing-statistic-wrapper .update-password .company-description .label label svg, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper label svg,
        .listing-statistic-wrapper .update-password .company-address .label label svg,
        .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper label svg,
        .listing-statistic-wrapper .update-password .edit-company-info .label label svg,
        .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper label svg,
        .listing-statistic-wrapper .company-name .company-description .label label svg,
        .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper label svg,
        .listing-statistic-wrapper .company-name .company-address .label label svg,
        .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper label svg,
        .listing-statistic-wrapper .company-name .edit-company-info .label label svg,
        .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper label svg,
        .listing-statistic-wrapper .contact-info .company-description .label label svg,
        .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper label svg,
        .listing-statistic-wrapper .contact-info .company-address .label label svg,
        .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper label svg,
        .listing-statistic-wrapper .contact-info .edit-company-info .label label svg,
        .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper label svg,
        .listing-statistic-wrapper .branding .company-description .label label svg,
        .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper label svg,
        .listing-statistic-wrapper .branding .company-address .label label svg,
        .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper label svg,
        .listing-statistic-wrapper .branding .edit-company-info .label label svg,
        .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper label svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper label svg {
          margin-left: 0.57rem; }
      .account-settings-wrapper .update-password .company-description .label h4, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper h4,
      .account-settings-wrapper .update-password .company-address .label h4,
      .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper h4,
      .account-settings-wrapper .update-password .edit-company-info .label h4,
      .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper h4,
      .account-settings-wrapper .company-name .company-description .label h4,
      .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper h4,
      .account-settings-wrapper .company-name .company-address .label h4,
      .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper h4,
      .account-settings-wrapper .company-name .edit-company-info .label h4,
      .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper h4,
      .account-settings-wrapper .contact-info .company-description .label h4,
      .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper h4,
      .account-settings-wrapper .contact-info .company-address .label h4,
      .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper h4,
      .account-settings-wrapper .contact-info .edit-company-info .label h4,
      .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper h4,
      .account-settings-wrapper .branding .company-description .label h4,
      .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper h4,
      .account-settings-wrapper .branding .company-address .label h4,
      .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper h4,
      .account-settings-wrapper .branding .edit-company-info .label h4,
      .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper h4, .listing-statistic-wrapper .update-password .company-description .label h4, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper h4,
      .listing-statistic-wrapper .update-password .company-address .label h4,
      .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper h4,
      .listing-statistic-wrapper .update-password .edit-company-info .label h4,
      .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper h4,
      .listing-statistic-wrapper .company-name .company-description .label h4,
      .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper h4,
      .listing-statistic-wrapper .company-name .company-address .label h4,
      .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper h4,
      .listing-statistic-wrapper .company-name .edit-company-info .label h4,
      .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper h4,
      .listing-statistic-wrapper .contact-info .company-description .label h4,
      .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper h4,
      .listing-statistic-wrapper .contact-info .company-address .label h4,
      .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper h4,
      .listing-statistic-wrapper .contact-info .edit-company-info .label h4,
      .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper h4,
      .listing-statistic-wrapper .branding .company-description .label h4,
      .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper h4,
      .listing-statistic-wrapper .branding .company-address .label h4,
      .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper h4,
      .listing-statistic-wrapper .branding .edit-company-info .label h4,
      .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h4,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper h4 {
        display: flex;
        align-items: center; }
      .account-settings-wrapper .update-password .company-description .label h5, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper h5,
      .account-settings-wrapper .update-password .company-address .label h5,
      .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper h5,
      .account-settings-wrapper .update-password .edit-company-info .label h5,
      .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper h5,
      .account-settings-wrapper .company-name .company-description .label h5,
      .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper h5,
      .account-settings-wrapper .company-name .company-address .label h5,
      .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper h5,
      .account-settings-wrapper .company-name .edit-company-info .label h5,
      .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper h5,
      .account-settings-wrapper .contact-info .company-description .label h5,
      .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper h5,
      .account-settings-wrapper .contact-info .company-address .label h5,
      .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper h5,
      .account-settings-wrapper .contact-info .edit-company-info .label h5,
      .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper h5,
      .account-settings-wrapper .branding .company-description .label h5,
      .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper h5,
      .account-settings-wrapper .branding .company-address .label h5,
      .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper h5,
      .account-settings-wrapper .branding .edit-company-info .label h5,
      .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper h5, .listing-statistic-wrapper .update-password .company-description .label h5, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper h5,
      .listing-statistic-wrapper .update-password .company-address .label h5,
      .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper h5,
      .listing-statistic-wrapper .update-password .edit-company-info .label h5,
      .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper h5,
      .listing-statistic-wrapper .company-name .company-description .label h5,
      .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper h5,
      .listing-statistic-wrapper .company-name .company-address .label h5,
      .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper h5,
      .listing-statistic-wrapper .company-name .edit-company-info .label h5,
      .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper h5,
      .listing-statistic-wrapper .contact-info .company-description .label h5,
      .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper h5,
      .listing-statistic-wrapper .contact-info .company-address .label h5,
      .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper h5,
      .listing-statistic-wrapper .contact-info .edit-company-info .label h5,
      .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper h5,
      .listing-statistic-wrapper .branding .company-description .label h5,
      .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper h5,
      .listing-statistic-wrapper .branding .company-address .label h5,
      .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper h5,
      .listing-statistic-wrapper .branding .edit-company-info .label h5,
      .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper h5 {
        display: flex;
        align-items: center;
        font-family: "Qartella", sans-serif;
        font-weight: 800;
        font-size: 1.05rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.4); }
        .account-settings-wrapper .update-password .company-description .label h5 svg, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper h5 svg,
        .account-settings-wrapper .update-password .company-address .label h5 svg,
        .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper h5 svg,
        .account-settings-wrapper .update-password .edit-company-info .label h5 svg,
        .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper h5 svg,
        .account-settings-wrapper .company-name .company-description .label h5 svg,
        .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper h5 svg,
        .account-settings-wrapper .company-name .company-address .label h5 svg,
        .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper h5 svg,
        .account-settings-wrapper .company-name .edit-company-info .label h5 svg,
        .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper h5 svg,
        .account-settings-wrapper .contact-info .company-description .label h5 svg,
        .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper h5 svg,
        .account-settings-wrapper .contact-info .company-address .label h5 svg,
        .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper h5 svg,
        .account-settings-wrapper .contact-info .edit-company-info .label h5 svg,
        .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper h5 svg,
        .account-settings-wrapper .branding .company-description .label h5 svg,
        .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper h5 svg,
        .account-settings-wrapper .branding .company-address .label h5 svg,
        .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper h5 svg,
        .account-settings-wrapper .branding .edit-company-info .label h5 svg,
        .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper h5 svg, .listing-statistic-wrapper .update-password .company-description .label h5 svg, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .update-password .company-address .label h5 svg,
        .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .update-password .edit-company-info .label h5 svg,
        .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .company-name .company-description .label h5 svg,
        .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .company-name .company-address .label h5 svg,
        .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .company-name .edit-company-info .label h5 svg,
        .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .contact-info .company-description .label h5 svg,
        .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .contact-info .company-address .label h5 svg,
        .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .contact-info .edit-company-info .label h5 svg,
        .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .branding .company-description .label h5 svg,
        .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .branding .company-address .label h5 svg,
        .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper h5 svg,
        .listing-statistic-wrapper .branding .edit-company-info .label h5 svg,
        .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h5 svg,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper h5 svg {
          margin-left: 0.73rem; }
      .account-settings-wrapper .update-password .company-description .label .upgrade, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper .upgrade,
      .account-settings-wrapper .update-password .company-address .label .upgrade,
      .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper .upgrade,
      .account-settings-wrapper .update-password .edit-company-info .label .upgrade,
      .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper .upgrade,
      .account-settings-wrapper .company-name .company-description .label .upgrade,
      .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper .upgrade,
      .account-settings-wrapper .company-name .company-address .label .upgrade,
      .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper .upgrade,
      .account-settings-wrapper .company-name .edit-company-info .label .upgrade,
      .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper .upgrade,
      .account-settings-wrapper .contact-info .company-description .label .upgrade,
      .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper .upgrade,
      .account-settings-wrapper .contact-info .company-address .label .upgrade,
      .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper .upgrade,
      .account-settings-wrapper .contact-info .edit-company-info .label .upgrade,
      .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper .upgrade,
      .account-settings-wrapper .branding .company-description .label .upgrade,
      .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper .upgrade,
      .account-settings-wrapper .branding .company-address .label .upgrade,
      .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper .upgrade,
      .account-settings-wrapper .branding .edit-company-info .label .upgrade,
      .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper .upgrade, .listing-statistic-wrapper .update-password .company-description .label .upgrade, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .update-password .company-address .label .upgrade,
      .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .update-password .edit-company-info .label .upgrade,
      .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .company-name .company-description .label .upgrade,
      .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .company-name .company-address .label .upgrade,
      .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .company-name .edit-company-info .label .upgrade,
      .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .contact-info .company-description .label .upgrade,
      .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .contact-info .company-address .label .upgrade,
      .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .contact-info .edit-company-info .label .upgrade,
      .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .branding .company-description .label .upgrade,
      .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .branding .company-address .label .upgrade,
      .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper .upgrade,
      .listing-statistic-wrapper .branding .edit-company-info .label .upgrade,
      .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade,
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper .upgrade {
        display: flex;
        align-items: center; }
        .account-settings-wrapper .update-password .company-description .label .upgrade p, .account-settings-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-description .select-input-wrapper .upgrade p,
        .account-settings-wrapper .update-password .company-address .label .upgrade p,
        .account-settings-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .company-address .select-input-wrapper .upgrade p,
        .account-settings-wrapper .update-password .edit-company-info .label .upgrade p,
        .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper .upgrade p,
        .account-settings-wrapper .company-name .company-description .label .upgrade p,
        .account-settings-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-description .select-input-wrapper .upgrade p,
        .account-settings-wrapper .company-name .company-address .label .upgrade p,
        .account-settings-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .company-address .select-input-wrapper .upgrade p,
        .account-settings-wrapper .company-name .edit-company-info .label .upgrade p,
        .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper .upgrade p,
        .account-settings-wrapper .contact-info .company-description .label .upgrade p,
        .account-settings-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-description .select-input-wrapper .upgrade p,
        .account-settings-wrapper .contact-info .company-address .label .upgrade p,
        .account-settings-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .company-address .select-input-wrapper .upgrade p,
        .account-settings-wrapper .contact-info .edit-company-info .label .upgrade p,
        .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper .upgrade p,
        .account-settings-wrapper .branding .company-description .label .upgrade p,
        .account-settings-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-description .select-input-wrapper .upgrade p,
        .account-settings-wrapper .branding .company-address .label .upgrade p,
        .account-settings-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .company-address .select-input-wrapper .upgrade p,
        .account-settings-wrapper .branding .edit-company-info .label .upgrade p,
        .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper .upgrade p, .listing-statistic-wrapper .update-password .company-description .label .upgrade p, .listing-statistic-wrapper .update-password .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-description .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .update-password .company-address .label .upgrade p,
        .listing-statistic-wrapper .update-password .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .company-address .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .update-password .edit-company-info .label .upgrade p,
        .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .company-name .company-description .label .upgrade p,
        .listing-statistic-wrapper .company-name .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-description .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .company-name .company-address .label .upgrade p,
        .listing-statistic-wrapper .company-name .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .company-address .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .company-name .edit-company-info .label .upgrade p,
        .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .contact-info .company-description .label .upgrade p,
        .listing-statistic-wrapper .contact-info .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-description .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .contact-info .company-address .label .upgrade p,
        .listing-statistic-wrapper .contact-info .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .company-address .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .contact-info .edit-company-info .label .upgrade p,
        .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .branding .company-description .label .upgrade p,
        .listing-statistic-wrapper .branding .company-description .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-description .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .branding .company-address .label .upgrade p,
        .listing-statistic-wrapper .branding .company-address .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .company-address .select-input-wrapper .upgrade p,
        .listing-statistic-wrapper .branding .edit-company-info .label .upgrade p,
        .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .upgrade p,
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper .upgrade p {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 0.88rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: #010101;
          opacity: 0.6;
          margin: 0;
          margin-right: 0.73rem; }
    .account-settings-wrapper .update-password .company-description .input,
    .account-settings-wrapper .update-password .company-address .input,
    .account-settings-wrapper .update-password .edit-company-info .input,
    .account-settings-wrapper .company-name .company-description .input,
    .account-settings-wrapper .company-name .company-address .input,
    .account-settings-wrapper .company-name .edit-company-info .input,
    .account-settings-wrapper .contact-info .company-description .input,
    .account-settings-wrapper .contact-info .company-address .input,
    .account-settings-wrapper .contact-info .edit-company-info .input,
    .account-settings-wrapper .branding .company-description .input,
    .account-settings-wrapper .branding .company-address .input,
    .account-settings-wrapper .branding .edit-company-info .input, .listing-statistic-wrapper .update-password .company-description .input,
    .listing-statistic-wrapper .update-password .company-address .input,
    .listing-statistic-wrapper .update-password .edit-company-info .input,
    .listing-statistic-wrapper .company-name .company-description .input,
    .listing-statistic-wrapper .company-name .company-address .input,
    .listing-statistic-wrapper .company-name .edit-company-info .input,
    .listing-statistic-wrapper .contact-info .company-description .input,
    .listing-statistic-wrapper .contact-info .company-address .input,
    .listing-statistic-wrapper .contact-info .edit-company-info .input,
    .listing-statistic-wrapper .branding .company-description .input,
    .listing-statistic-wrapper .branding .company-address .input,
    .listing-statistic-wrapper .branding .edit-company-info .input {
      margin-top: 0; }
  .account-settings-wrapper .update-password .company-address .input,
  .account-settings-wrapper .update-password .edit-company-info .input,
  .account-settings-wrapper .company-name .company-address .input,
  .account-settings-wrapper .company-name .edit-company-info .input,
  .account-settings-wrapper .contact-info .company-address .input,
  .account-settings-wrapper .contact-info .edit-company-info .input,
  .account-settings-wrapper .branding .company-address .input,
  .account-settings-wrapper .branding .edit-company-info .input, .listing-statistic-wrapper .update-password .company-address .input,
  .listing-statistic-wrapper .update-password .edit-company-info .input,
  .listing-statistic-wrapper .company-name .company-address .input,
  .listing-statistic-wrapper .company-name .edit-company-info .input,
  .listing-statistic-wrapper .contact-info .company-address .input,
  .listing-statistic-wrapper .contact-info .edit-company-info .input,
  .listing-statistic-wrapper .branding .company-address .input,
  .listing-statistic-wrapper .branding .edit-company-info .input {
    margin-top: 1.26rem; }
  
  .account-settings-wrapper .update-password .edit-company-info .label,
  .account-settings-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .update-password .edit-company-info .select-input-wrapper,
  .account-settings-wrapper .company-name .edit-company-info .label,
  .account-settings-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .company-name .edit-company-info .select-input-wrapper,
  .account-settings-wrapper .contact-info .edit-company-info .label,
  .account-settings-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .contact-info .edit-company-info .select-input-wrapper,
  .account-settings-wrapper .branding .edit-company-info .label,
  .account-settings-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .account-settings-wrapper .branding .edit-company-info .select-input-wrapper,
  .listing-statistic-wrapper .update-password .edit-company-info .label,
  .listing-statistic-wrapper .update-password .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .update-password .edit-company-info .select-input-wrapper,
  .listing-statistic-wrapper .company-name .edit-company-info .label,
  .listing-statistic-wrapper .company-name .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .company-name .edit-company-info .select-input-wrapper,
  .listing-statistic-wrapper .contact-info .edit-company-info .label,
  .listing-statistic-wrapper .contact-info .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .contact-info .edit-company-info .select-input-wrapper,
  .listing-statistic-wrapper .branding .edit-company-info .label,
  .listing-statistic-wrapper .branding .edit-company-info .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper,
  .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .listing-statistic-wrapper .branding .edit-company-info .select-input-wrapper {
    margin-bottom: -1.57rem; }
  .account-settings-wrapper .update-password .edit-company-info:first-child .input,
  .account-settings-wrapper .company-name .edit-company-info:first-child .input,
  .account-settings-wrapper .contact-info .edit-company-info:first-child .input,
  .account-settings-wrapper .branding .edit-company-info:first-child .input, .listing-statistic-wrapper .update-password .edit-company-info:first-child .input,
  .listing-statistic-wrapper .company-name .edit-company-info:first-child .input,
  .listing-statistic-wrapper .contact-info .edit-company-info:first-child .input,
  .listing-statistic-wrapper .branding .edit-company-info:first-child .input {
    margin-top: 0 !important; }
  .account-settings-wrapper .acc-type, .listing-statistic-wrapper .acc-type {
    padding: 1rem 1.15rem 1rem 4.36rem;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.08); }
    @media (max-width: 991px) {
      .account-settings-wrapper .acc-type, .listing-statistic-wrapper .acc-type {
        padding: 1.63rem 1.68rem 1.63rem 1.68rem; } }
    @media (max-width: 767px) {
      .account-settings-wrapper .acc-type, .listing-statistic-wrapper .acc-type {
        padding: 1.63rem 0.84rem 1.36rem 0.84rem; } }
    .account-settings-wrapper .acc-type h3, .listing-statistic-wrapper .acc-type h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 1.03rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin: 0;
      margin-right: 2.63rem; }
    .account-settings-wrapper .acc-type .check, .listing-statistic-wrapper .acc-type .check {
      display: flex;
      align-items: center; }

.phone-select-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.84rem; }
  .phone-select-wrapper input {
    margin-left: 0.27rem;
    margin-top: 0 !important; }

.listing-statistic-wrapper:first-child {
  margin: 0.97rem 2.1rem 1.15rem 2.1rem; }
  @media (max-width: 991px) {
    .listing-statistic-wrapper:first-child {
      margin: 0.97rem 1.68rem 3.15rem 1.68rem; } }
  @media (max-width: 767px) {
    .listing-statistic-wrapper:first-child {
      margin: 0.97rem 0.72rem 3.15rem 0.42rem; } }

.listing-statistic-wrapper .content .tabs {
  pointer-events: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2.52rem; }
  @media (max-width: 767px) {
    .listing-statistic-wrapper .content .tabs {
      display: block; }
      .listing-statistic-wrapper .content .tabs a {
        margin-bottom: 1rem; } }
  .listing-statistic-wrapper .content .tabs a {
    background: #ffffff;
    border: 1px solid rgba(1, 1, 1, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(130, 165, 193, 0.25);
    border-radius: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    justify-content: center;
    margin-right: 1.84rem;
    text-decoration: unset; }
    .listing-statistic-wrapper .content .tabs a button {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: transparent;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 1.05rem;
      line-height: 1.1;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.2;
      display: flex;
      align-items: center;
      justify-content: center; }
    .listing-statistic-wrapper .content .tabs a:last-child {
      margin-right: 0; }
    .listing-statistic-wrapper .content .tabs a svg {
      margin-right: 0.5rem; }
  .listing-statistic-wrapper .content .tabs .active {
    background-color: #3176bc; }
    .listing-statistic-wrapper .content .tabs .active button {
      opacity: 1;
      color: #ffffff; }

.listing-statistic-wrapper .content .listing-info-wrapper .listing-info {
  display: grid;
  grid-template-columns: 2fr 5fr; }
  @media (max-width: 991px) {
    .listing-statistic-wrapper .content .listing-info-wrapper .listing-info {
      grid-template-columns: 2.5fr 2fr; } }
  @media (max-width: 767px) {
    .listing-statistic-wrapper .content .listing-info-wrapper .listing-info {
      display: block; } }
  .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr; }
    @media (max-width: 767px) {
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left {
        display: block; } }
    .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .listing-image {
      width: 100%;
      display: flex;
      justify-content: center; }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .listing-image img {
        height: 100%;
        border-radius: 10px;
        object-fit: cover; }
        @media (max-width: 991px) {
          .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .listing-image img {
            max-height: 10rem;
            margin-bottom: 1rem;
            max-width: 100%;
            display: block; } }
    .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .info {
      background-color: #3176bc;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
      border-radius: 10px;
      margin-left: 1.26rem; }
      @media (max-width: 767px) {
        .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .info {
          display: block;
          width: 46%;
          margin-left: unset;
          float: left; } }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .info h4 {
        color: #ffffff;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 0.88rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 0.89rem;
        padding-bottom: 0.89rem;
        padding-left: 1.89rem; }
        .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .left .info h4:last-child {
          border: 0; }
  .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
    border-radius: 10px;
    margin-left: 1.26rem; }
    @media (max-width: 767px) {
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right {
        display: block;
        width: 46%;
        margin-left: unset;
        float: right; } }
    .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info:last-child {
        border: 0; }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info > div {
        display: flex;
        width: 40%;
        align-items: center; }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info h6 {
        padding-top: 0.84rem;
        padding-bottom: 0.84rem;
        padding-left: 1.15rem;
        padding-right: 1.15rem;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        margin: 0;
        width: 55%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        @media (max-width: 991px) {
          .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info h6 {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis; } }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info .blue {
        width: 106px;
        text-align: center;
        height: 4.1rem;
        background-color: #3176bc;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem; }
        .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info .blue h3 {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 0.88rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: #ffffff;
          margin: 0; }
      .listing-statistic-wrapper .content .listing-info-wrapper .listing-info .right .info h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.6;
        width: 20%;
        padding-top: 20px;
        padding-bottom: 20px;
        white-space: nowrap; }

.listing-statistic-wrapper .content .listing-info-wrapper .buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.26rem;
  margin-bottom: 2.52rem; }
  .listing-statistic-wrapper .content .listing-info-wrapper .buttons button {
    box-shadow: 0px 0px 12px rgba(209, 26, 42, 0.18);
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #ffffff;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0.68rem 1.21rem; }
    @media (max-width: 767px) {
      .listing-statistic-wrapper .content .listing-info-wrapper .buttons button {
        width: 100%;
        margin-top: 1rem; } }
    .listing-statistic-wrapper .content .listing-info-wrapper .buttons button svg {
      margin-right: 0.5rem; }
    .listing-statistic-wrapper .content .listing-info-wrapper .buttons button:first-child {
      background-color: #d0342c;
      margin-right: 1rem; }
    .listing-statistic-wrapper .content .listing-info-wrapper .buttons button:last-child {
      background-color: #00ab66; }

.listing-statistic-wrapper .content .filter {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .listing-statistic-wrapper .content .filter .wrapper-date-input .content {
    padding: 0; }
  @media (max-width: 991px) {
    .listing-statistic-wrapper .content .filter .export {
      display: none; } }
  .listing-statistic-wrapper .content .filter .export button {
    background: #008575;
    box-shadow: 0px 0px 12px rgba(0, 133, 117, 0.13);
    color: #ffffff;
    display: flex;
    align-items: center;
    border: 0;
    padding: 0.78rem 2.21rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.78rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #ffffff; }
    .listing-statistic-wrapper .content .filter .export button svg {
      margin-right: 0.6rem; }

.listing-statistic-wrapper .content .statistic-charts-wrapper h3 {
  font-family: "Qartella", sans-serif;
  font-weight: 800;
  font-size: 1.05rem;
  line-height: 1.1rem;
  letter-spacing: 0.1px;
  color: #010101;
  margin: 0;
  margin-bottom: 1.52rem; }

.listing-statistic-wrapper .content .statistic-charts-wrapper .statistic-charts {
  display: grid;
  grid-template-columns: 2.5fr 2.5fr;
  grid-column-gap: 3.21rem;
  -webkit-column-gap: 3.21rem;
          column-gap: 3.21rem;
  grid-row-gap: 2.36rem;
  row-gap: 2.36rem;
  margin-bottom: 3.15rem; }
  @media (max-width: 991px) {
    .listing-statistic-wrapper .content .statistic-charts-wrapper .statistic-charts {
      display: unset; } }

.edit-listing {
  display: grid;
  grid-template-columns: 2fr 10fr; }
  .edit-listing .error {
    color: red;
    font-size: 0.84rem;
    line-height: 1rem;
    margin-top: 0.5rem; }
  .edit-listing label {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #010101;
    margin-bottom: 0.84rem;
    display: inline-block;
    margin-top: 1rem;
    white-space: nowrap; }
  @media (max-width: 991px) {
    .edit-listing {
      display: block; } }
  .edit-listing .img-wrapper img {
    object-fit: cover; }
  @media (max-width: 991px) {
    .edit-listing .img-wrapper {
      max-width: 30%; } }
  @media (max-width: 767px) {
    .edit-listing .img-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%; }
      .edit-listing .img-wrapper img {
        width: 50%; }
      .edit-listing .img-wrapper button {
        max-width: 50%; } }
  .edit-listing .img-wrapper button {
    background-color: #3176bc;
    color: #ffffff;
    border: 0;
    box-shadow: 0px 4px 12px rgba(109, 141, 173, 0.25);
    border-radius: 5px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.73rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding: 0.78rem 0;
    margin-top: 1rem;
    position: relative; }
    .edit-listing .img-wrapper button input {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      cursor: pointer; }
  .edit-listing .edit-form-wrapper {
    margin-left: 4.05rem; }
    @media (max-width: 991px) {
      .edit-listing .edit-form-wrapper {
        margin-left: 0;
        margin-top: 2rem; } }
  .edit-listing .listing-edit-form {
    padding: 1.57rem;
    background: rgba(49, 118, 188, 0.1);
    border-radius: 10px; }
    @media (max-width: 991px) {
      .edit-listing .listing-edit-form .input {
        max-width: 100% !important; } }
    .edit-listing .listing-edit-form .seller-info .input {
      max-width: 100% !important;
      width: 100%; }
    .edit-listing .listing-edit-form .form .google-maps-search {
      max-width: 25.1rem; }
      @media (max-width: 991px) {
        .edit-listing .listing-edit-form .form .google-maps-search {
          max-width: 100%;
          width: 100%; } }
      .edit-listing .listing-edit-form .form .google-maps-search .input-wrap {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        border: 0;
        padding-bottom: 0.84rem;
        padding-top: 0.84rem;
        box-sizing: border-box;
        padding-left: 0.84rem;
        padding-right: 0.84rem;
        display: flex;
        align-items: center; }
        .edit-listing .listing-edit-form .form .google-maps-search .input-wrap input {
          border: 0;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.88rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.8);
          box-sizing: border-box;
          margin-left: 0.89rem; }
    .edit-listing .listing-edit-form .form .wrapper-listing-section {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 991px) {
        .edit-listing .listing-edit-form .form .wrapper-listing-section .custom-toggle-button {
          display: none; } }
      .edit-listing .listing-edit-form .form .wrapper-listing-section .input {
        width: 100%; }
    .edit-listing .listing-edit-form .form .wrapper-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem; }
      @media (max-width: 991px) {
        .edit-listing .listing-edit-form .form .wrapper-title .custom-toggle-button {
          display: none; } }
      .edit-listing .listing-edit-form .form .wrapper-title h1 {
        margin-bottom: 0;
        margin-top: 0; }
      .edit-listing .listing-edit-form .form .wrapper-title > div {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        @media (max-width: 480px) {
          .edit-listing .listing-edit-form .form .wrapper-title > div {
            display: block; } }
    .edit-listing .listing-edit-form .form .select-view {
      position: relative; }
      .edit-listing .listing-edit-form .form .select-view .select-view-input {
        padding: 0.42rem 0.84rem;
        min-height: 1.8rem;
        display: flex;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        cursor: pointer; }
        .edit-listing .listing-edit-form .form .select-view .select-view-input span {
          background: rgba(0, 0, 0, 0.05);
          border-radius: 15px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.88rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: #010101;
          margin-right: 0.74rem;
          padding: 0.5rem 1rem; }
      .edit-listing .listing-edit-form .form .select-view .options {
        width: 100%;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-top: 1rem; }
        .edit-listing .listing-edit-form .form .select-view .options > div {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.88rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #010101;
          display: flex;
          align-items: center;
          margin-bottom: 0.73rem;
          margin-left: 0.73rem; }
          .edit-listing .listing-edit-form .form .select-view .options > div .check {
            cursor: pointer;
            margin-right: 0.63rem;
            border: 1px solid #010101;
            width: 1.42rem;
            height: 1.42rem;
            display: flex;
            align-items: center;
            justify-content: center; }
            .edit-listing .listing-edit-form .form .select-view .options > div .check svg {
              display: none; }
          .edit-listing .listing-edit-form .form .select-view .options > div .checked {
            background-color: #010101; }
            .edit-listing .listing-edit-form .form .select-view .options > div .checked svg {
              display: block; }
    .edit-listing .listing-edit-form .form:first-child h1 {
      margin-top: 0; }
    .edit-listing .listing-edit-form .form h1 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 1.05rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 1rem;
      margin-top: 2rem; }
    .edit-listing .listing-edit-form .form p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101; }
    .edit-listing .listing-edit-form .form input,
    .edit-listing .listing-edit-form .form textarea {
      box-sizing: border-box; }
    .edit-listing .listing-edit-form .form .input {
      max-width: 25.1rem; }
      .edit-listing .listing-edit-form .form .input input {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        border: 0;
        padding-bottom: 0.84rem;
        padding-top: 0.84rem;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.8);
        padding-left: 1.36rem;
        margin-right: 1rem;
        margin-top: 0;
        margin-bottom: 0; }
      .edit-listing .listing-edit-form .form .input .price {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 1.3rem; }
        .edit-listing .listing-edit-form .form .input .price .error {
          position: absolute;
          bottom: -1.4rem; }
        .edit-listing .listing-edit-form .form .input .price .select-input-wrapper {
          margin-bottom: 0rem; }
          @media (max-width: 967px) {
            .edit-listing .listing-edit-form .form .input .price .select-input-wrapper {
              margin-top: 0; } }
      .edit-listing .listing-edit-form .form .input .select-input-wrapper {
        background-color: #ffffff;
        border: 0;
        margin-bottom: 0.84rem; }
    .edit-listing .listing-edit-form .form .textarea {
      max-width: 100%;
      margin-top: 1rem; }
      .edit-listing .listing-edit-form .form .textarea textarea {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        min-height: 12.57rem;
        max-width: 100%;
        border: 0;
        padding-bottom: 0.84rem;
        padding-top: 0.84rem;
        padding-left: 0.84rem;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 1.24rem;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.7); }
    .edit-listing .listing-edit-form .form .headline {
      position: relative; }
      .edit-listing .listing-edit-form .form .headline .lenght {
        position: absolute;
        right: 5px;
        bottom: 0rem;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.73rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.7; }
      .edit-listing .listing-edit-form .form .headline label {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        color: #010101;
        margin-bottom: 0.84rem;
        display: inline-block; }
      .edit-listing .listing-edit-form .form .headline input {
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        border: 0;
        padding-bottom: 0.84rem;
        padding-top: 0.84rem;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.8);
        padding-left: 1.36rem;
        margin-right: 1rem; }
    .edit-listing .listing-edit-form .form .input-wrapper {
      display: flex;
      align-items: center;
      margin-top: 1rem; }
      @media (max-width: 767px) {
        .edit-listing .listing-edit-form .form .input-wrapper {
          display: block; } }
      .edit-listing .listing-edit-form .form .input-wrapper .input {
        max-width: 100%;
        width: 100%;
        margin-right: 1rem; }
        @media (max-width: 767px) {
          .edit-listing .listing-edit-form .form .input-wrapper .input {
            margin-bottom: 1rem; } }
        .edit-listing .listing-edit-form .form .input-wrapper .input:last-child {
          margin-right: 0; }
  .edit-listing button {
    width: 100%;
    border: 0;
    background-color: #3176bc;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 1.05rem;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding: 0.84rem;
    border-radius: 10px;
    margin-top: 1rem; }

.dropzone-wrapper {
  position: relative;
  min-height: 11.89rem; }
  .dropzone-wrapper .dropzone {
    background-color: #ffffff;
    border: 2px dashed rgba(1, 1, 1, 0.5);
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .dropzone-wrapper .dropzone:focus {
      outline: none !important; }
    .dropzone-wrapper .dropzone button {
      background: #010101;
      box-shadow: 0px 0px 12px rgba(1, 1, 1, 0.13);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      border-radius: 0;
      max-width: 13.1rem;
      border: 0;
      padding: 1rem 1.94rem; }
  .dropzone-wrapper .preview {
    padding: 1.26rem 0.52rem;
    display: grid;
    grid-template-columns: 2.4fr 2.4fr 2.4fr 2.4fr 2.4fr;
    grid-column-gap: 1.36rem;
    -webkit-column-gap: 1.36rem;
            column-gap: 1.36rem;
    grid-row-gap: 0.94rem;
    row-gap: 0.94rem; }
    @media (max-width: 767px) {
      .dropzone-wrapper .preview {
        grid-template-columns: 2.4fr 2.4fr 2.4fr; } }
    .dropzone-wrapper .preview > div {
      position: relative; }
      .dropzone-wrapper .preview > div .remove {
        all: unset;
        position: absolute;
        border: 0;
        background-color: transparent;
        right: 0.32rem;
        top: 0.32rem; }
      .dropzone-wrapper .preview > div img {
        width: 100%;
        height: 7.89rem;
        object-fit: cover;
        border-radius: 5px; }

.promotion-wrapper .promote {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(109, 141, 173, 0.18);
  border-radius: 15px;
  padding: 2.52rem 2.68rem;
  margin-bottom: 3.15rem; }
  @media (max-width: 767px) {
    .promotion-wrapper .promote {
      padding: 2.52rem 0.68rem; } }
  .promotion-wrapper .promote .header {
    text-align: center; }
    .promotion-wrapper .promote .header h1 {
      font-family: "Qartella", sans-serif;
      font-weight: 400;
      font-size: 1.78rem;
      line-height: rem;
      letter-spacing: 0.1px;
      color: #3176bc; }
    .promotion-wrapper .promote .header p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.6;
      margin: 0;
      margin-top: 1.26rem;
      margin-bottom: 3.78rem; }
  .promotion-wrapper .promote .promote-wrapper {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 3.78rem;
    -webkit-column-gap: 3.78rem;
            column-gap: 3.78rem; }
    @media (max-width: 991px) {
      .promotion-wrapper .promote .promote-wrapper {
        grid-template-columns: 12fr; } }
    .promotion-wrapper .promote .promote-wrapper .left {
      background: rgba(49, 118, 188, 0.02);
      border-radius: 15px;
      padding: 2.68rem 2.31rem; }
      @media (max-width: 991px) {
        .promotion-wrapper .promote .promote-wrapper .left {
          order: 1;
          padding: 0; } }
      .promotion-wrapper .promote .promote-wrapper .left .active h3,
      .promotion-wrapper .promote .promote-wrapper .left .active svg {
        opacity: 1; }
      .promotion-wrapper .promote .promote-wrapper .left > div {
        display: flex;
        justify-content: space-between; }
        .promotion-wrapper .promote .promote-wrapper .left > div h3 {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 0.88rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #010101;
          opacity: 0.3; }
        .promotion-wrapper .promote .promote-wrapper .left > div svg {
          opacity: 0.3; }
    @media (max-width: 991px) {
      .promotion-wrapper .promote .promote-wrapper .right {
        order: 0; } }
    .promotion-wrapper .promote .promote-wrapper .right .active {
      background: linear-gradient(90deg, #3176bc -3.43%, #4ebbb8 104.41%);
      box-shadow: 0px 0px 12px rgba(49, 118, 188, 0.25);
      border-radius: 15px; }
      .promotion-wrapper .promote .promote-wrapper .right .active > div {
        display: flex;
        align-items: center; }
        .promotion-wrapper .promote .promote-wrapper .right .active > div svg {
          margin-right: 1.73rem; }
        .promotion-wrapper .promote .promote-wrapper .right .active > div h2 {
          color: #ffffff; }
        .promotion-wrapper .promote .promote-wrapper .right .active > div span {
          color: #3176bc;
          background-color: #ffffff; }
      .promotion-wrapper .promote .promote-wrapper .right .active h6 {
        color: #ffffff; }
        .promotion-wrapper .promote .promote-wrapper .right .active h6 span {
          color: #ffffff; }
    .promotion-wrapper .promote .promote-wrapper .right > div {
      cursor: pointer;
      padding-left: 2.08rem;
      padding-right: 1.57rem;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 991px) {
        .promotion-wrapper .promote .promote-wrapper .right > div {
          padding-left: 0.57rem;
          padding-right: 0.57rem; } }
      .promotion-wrapper .promote .promote-wrapper .right > div > div {
        display: flex;
        align-items: center; }
        .promotion-wrapper .promote .promote-wrapper .right > div > div svg {
          margin-right: 1.73rem; }
        .promotion-wrapper .promote .promote-wrapper .right > div > div h2 {
          font-family: "Qartella", sans-serif;
          font-weight: 800;
          font-size: 1.52;
          line-height: 1.52rem;
          letter-spacing: 0.1px;
          color: #3176bc;
          margin: 0;
          margin-bottom: 0.84rem; }
        .promotion-wrapper .promote .promote-wrapper .right > div > div span {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 0.88rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          background: #3176bc;
          border-radius: 15px;
          color: #ffffff;
          padding: 0.21rem 0.47rem; }
      .promotion-wrapper .promote .promote-wrapper .right > div h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 2.21rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px;
        color: #3176bc;
        display: flex;
        align-items: center; }
        .promotion-wrapper .promote .promote-wrapper .right > div h6 span {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 0.84rem;
          line-height: 1.05rem;
          letter-spacing: 0.1px;
          color: #3176bc;
          margin-left: 0.3rem; }

.promotion-wrapper .look-wrapper h1 {
  font-family: "Qartella", sans-serif;
  font-weight: 800;
  font-size: 1.05rem;
  line-height: 1.1rem;
  letter-spacing: 0.1px;
  color: #010101;
  margin: 0;
  margin-bottom: 2rem; }

.promotion-wrapper .look-wrapper .mockup {
  box-shadow: 0px 0px 16px rgba(109, 141, 173, 0.18);
  border-radius: 15px; }

.promotion-wrapper .look-wrapper .desc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9.47rem;
  padding-left: 3.78rem;
  padding-right: 3.78rem; }
  @media (max-width: 991px) {
    .promotion-wrapper .look-wrapper .desc {
      display: block; }
      .promotion-wrapper .look-wrapper .desc > div {
        margin-bottom: 2rem; } }
  .promotion-wrapper .look-wrapper .desc > div {
    text-align: center; }
    .promotion-wrapper .look-wrapper .desc > div h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 1.05rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      margin: 0;
      margin-bottom: 0.52rem; }
    .promotion-wrapper .look-wrapper .desc > div p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.05rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.6;
      margin: 0; }

.promotion-wrapper .faq-wrapper {
  text-align: center; }
  .promotion-wrapper .faq-wrapper .question {
    text-align: start; }
  .promotion-wrapper .faq-wrapper .header h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 0.88rem;
    line-height: 1.05rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #010101;
    margin-bottom: 2.52rem; }
  .promotion-wrapper .faq-wrapper .header h1 {
    font-family: "Qartella", sans-serif;
    font-weight: 800;
    font-size: 2.15rem;
    line-height: 3.15rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.9;
    margin: 0; }
  .promotion-wrapper .faq-wrapper .header h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.05rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #010101;
    margin: 0;
    margin-top: 3.15rem;
    margin-bottom: 4.31rem; }
  .promotion-wrapper .faq-wrapper h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.05rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #010101;
    margin: 0;
    margin-top: 3.78rem; }
    .promotion-wrapper .faq-wrapper h4 a {
      color: #3176bc; }

.custom-toggle-button {
  display: flex;
  align-items: center; }
  .custom-toggle-button .switch {
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    width: 2.15rem;
    height: 1.26rem; }
    .custom-toggle-button .switch input {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    .custom-toggle-button .switch .slider {
      color: #ffffff;
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #04040447;
      transition: 0.4s; }
      .custom-toggle-button .switch .slider:before {
        position: absolute;
        content: "";
        height: 1.05rem;
        width: 1.05rem;
        left: 0.13rem;
        bottom: 0.12rem;
        background-color: #ffffff;
        transition: 0.4s; }
    .custom-toggle-button .switch input:checked + .slider {
      background-color: #3176bc; }
    .custom-toggle-button .switch input:focus + .slider {
      box-shadow: 0 0 1px #3176bc; }
    .custom-toggle-button .switch input:checked + .slider:before {
      -webkit-transform: translateX(0.9rem);
      transform: translateX(0.9rem); }
    .custom-toggle-button .switch .slider.round {
      border-radius: 1.78rem; }
    .custom-toggle-button .switch .slider.round:before {
      border-radius: 1.78rem; }

.account-page-navbar-wrapper {
  display: flex;
  justify-content: center;
  padding: 1.26rem 5.26rem;
  background-color: #ffffff;
  position: relative;
  padding-bottom: 1.94rem; }
  .account-page-navbar-wrapper svg {
    max-width: 10.42rem; }
  .account-page-navbar-wrapper button {
    background: #3176bc;
    box-shadow: 0px 4px 16px rgba(49, 118, 188, 0.18), inset 0px 4px 16px rgba(47, 128, 237, 0.18);
    border-radius: 30px;
    padding: 0.63rem 2.68rem;
    border: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.84rem;
    line-height: 1.26rem;
    color: #ffffff;
    position: absolute;
    right: 5.26rem; }
    @media (max-width: 991px) {
      .account-page-navbar-wrapper button {
        right: 2.05rem; } }
    @media (max-width: 991px) {
      .account-page-navbar-wrapper button {
        right: 0.84rem; } }

.account-page-footer {
  background: #ffffff;
  box-shadow: 16px 0px 32px rgba(109, 141, 173, 0.08); }
  .account-page-footer .content {
    padding: 2.63rem 11.31rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 991px) {
      .account-page-footer .content {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0; } }
    .account-page-footer .content p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.71rem;
      line-height: 1.26rem;
      color: #010101;
      opacity: 0.8; }
    @media (max-width: 991px) {
      .account-page-footer .content .left {
        text-align: center; } }
    .account-page-footer .content .left p {
      margin: 0;
      margin-top: 1.05rem; }
    .account-page-footer .content .left .social-network {
      margin-top: 4.21rem;
      display: flex; }
      .account-page-footer .content .left .social-network svg {
        margin-right: 1.31rem; }
    @media (max-width: 991px) {
      .account-page-footer .content .right {
        text-align: center;
        margin-top: 10.52rem; } }
    .account-page-footer .content .right h2 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.05rem;
      line-height: 1.26rem;
      color: #010101;
      margin: 0;
      margin-bottom: 1.05rem; }
    .account-page-footer .content .right p {
      margin: 0;
      margin-bottom: 0.42rem; }
    .account-page-footer .content .right a {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.71rem;
      line-height: 1.26rem;
      color: #010101;
      opacity: 0.8;
      text-decoration: none; }
  .account-page-footer .rights {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #3176bc;
    padding: 1.26rem; }
    .account-page-footer .rights h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.52;
      line-height: 1.26rem;
      color: #ffffff;
      margin: 0; }

.page-account-wrapper .gradient-wrapper {
  background: linear-gradient(90deg, #3176bc -3.43%, #4ebbb8 104.41%);
  box-shadow: 0px 4px 12px rgba(49, 118, 188, 0.18);
  width: 100%;
  text-align: center;
  padding: 1.57rem 0;
  margin-top: 1.89rem; }
  .page-account-wrapper .gradient-wrapper h1 {
    font-family: "Qartella", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1.26rem;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
    color: #ffffff; }
  .page-account-wrapper .gradient-wrapper p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: #ffffff; }
  .page-account-wrapper .gradient-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.63rem; }
    @media (max-width: 767px) {
      .page-account-wrapper .gradient-wrapper > div {
        flex-direction: column; } }
    .page-account-wrapper .gradient-wrapper > div h2 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.57rem;
      line-height: 2.21rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      text-align: start;
      margin-right: 2.8rem; }
    .page-account-wrapper .gradient-wrapper > div button {
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(49, 118, 188, 0.25);
      border-radius: 10px;
      border: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.84rem;
      line-height: 1.26rem;
      letter-spacing: 0.1px;
      color: #3176bc;
      padding: 1.05rem 4.21rem; }
      .page-account-wrapper .gradient-wrapper > div button span {
        font-weight: 700; }

@media (max-width: 767px) {
  .page-account-wrapper .title-wrapper {
    padding: 0 1.52rem; } }

@media (max-width: 991px) {
  .page-account-wrapper .title-wrapper {
    padding: 0 3.68rem; } }

.page-account-wrapper .folowers-wrapper {
  text-align: center;
  margin-top: 1.68rem;
  margin-bottom: 6.31rem; }
  @media (max-width: 991px) {
    .page-account-wrapper .folowers-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .page-account-wrapper .folowers-wrapper h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #333333;
    margin-bottom: 2.52rem; }
  .page-account-wrapper .folowers-wrapper .content {
    text-align: left;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    padding-left: 9.47rem;
    padding-right: 5rem;
    align-items: baseline;
    grid-column-gap: 6.57rem;
    -webkit-column-gap: 6.57rem;
            column-gap: 6.57rem; }
    @media (max-width: 991px) {
      .page-account-wrapper .folowers-wrapper .content {
        display: block;
        padding: 0; } }
    @media (max-width: 767px) {
      .page-account-wrapper .folowers-wrapper .content {
        padding-left: 75px;
        padding-right: 75px; } }
    .page-account-wrapper .folowers-wrapper .content > div {
      overflow: hidden;
      width: 100%;
      margin-bottom: 3.15rem; }
      .page-account-wrapper .folowers-wrapper .content > div .logo {
        margin-bottom: 1.84rem;
        text-align: center;
        width: 100%;
        max-width: 14.94rem; }
        @media (max-width: 991px) {
          .page-account-wrapper .folowers-wrapper .content > div .logo {
            max-width: 19.94rem; } }
        @media (max-width: 767px) {
          .page-account-wrapper .folowers-wrapper .content > div .logo {
            max-width: unset; } }
        .page-account-wrapper .folowers-wrapper .content > div .logo svg {
          max-width: 10.42rem; }

.page-account-wrapper .customer-wrapper {
  margin-top: 3.78rem; }
  .page-account-wrapper .customer-wrapper .customer {
    margin-top: 3.15rem;
    margin-bottom: 6.21rem;
    display: grid;
    padding-left: 10.52rem;
    padding-right: 10.52rem;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 2.52rem;
    -webkit-column-gap: 2.52rem;
            column-gap: 2.52rem; }
    @media (max-width: 991px) {
      .page-account-wrapper .customer-wrapper .customer {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    @media (max-width: 767px) {
      .page-account-wrapper .customer-wrapper .customer {
        padding-left: 0.84rem;
        padding-right: 0.84rem; } }
    .page-account-wrapper .customer-wrapper .customer > div {
      margin-top: 2.89rem; }
      .page-account-wrapper .customer-wrapper .customer > div .item {
        background: #3176bc;
        border: 1px solid #3176bc;
        box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.25);
        border-radius: 10px;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 0.89rem 1.1rem;
        margin-bottom: 0.84rem; }
        .page-account-wrapper .customer-wrapper .customer > div .item svg {
          margin-right: 0.78rem; }
        .page-account-wrapper .customer-wrapper .customer > div .item .heading {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 1.05re;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: #ffffff; }
        .page-account-wrapper .customer-wrapper .customer > div .item .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 0.73rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
          color: #ffffff; }
        .page-account-wrapper .customer-wrapper .customer > div .item:last-child {
          padding: 0.4rem 0.6rem 0rem 0.6rem; }

.first {
  margin-bottom: 2.63rem; }
  @media (max-width: 991px) {
    .first .content {
      top: 65% !important; } }
  @media (max-width: 1366px) {
    .first .content {
      top: 65% !important; } }

.one-click {
  position: relative; }
  .one-click img {
    object-fit: cover; }
  @media (max-width: 991px) {
    .one-click img {
      min-height: 440px !important; } }
  @media (max-width: 1366px) {
    .one-click img {
      min-height: 540px; } }
  .one-click .totaly-free {
    background: #3176bc;
    border: 3px dashed #ffffff;
    border-radius: 0px 0px 50px 50px;
    position: absolute;
    top: 0;
    right: 4.8rem;
    padding: 0.89rem 1rem 2.63rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 991px) {
      .one-click .totaly-free {
        right: 2.05rem; } }
    @media (max-width: 767px) {
      .one-click .totaly-free {
        right: 0.84rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    .one-click .totaly-free h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 800;
      font-size: 1.26rem;
      line-height: 1.26rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin: 0;
      margin-top: 0.42rem; }
    .one-click .totaly-free .until {
      margin-top: 0.31rem;
      margin-bottom: 0.42rem;
      background: #ffffff;
      border: 1px solid #02509F;
      border-radius: 20px;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 0.57rem;
      line-height: 0.84rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #3176bc;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0.21rem 1.31rem; }
    .one-click .totaly-free .date {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.57rem;
      line-height: 0.84rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin-bottom: 0.42rem; }
    .one-click .totaly-free .stars {
      display: flex;
      align-items: center; }
      .one-click .totaly-free .stars::after {
        content: '';
        display: inline-block;
        width: 1.73rem;
        height: 2px;
        background-color: #ffffff;
        margin-left: 4px; }
      .one-click .totaly-free .stars::before {
        content: '';
        display: inline-block;
        width: 33px;
        height: 2px;
        background-color: #ffffff;
        margin-right: 4px; }
  .one-click .content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    width: 100%; }
    @media (max-width: 767px) {
      .one-click .content {
        width: 98%; } }
    .one-click .content h2 {
      font-family: "Qartella", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 1.78rem;
      line-height: 2rem;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin: 0; }
    .one-click .content h3 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.84rem;
      line-height: 1.47rem;
      text-align: center;
      color: #ffffff; }
      .one-click .content h3 span {
        font-weight: 700; }
    .one-click .content > div {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .one-click .content > div .input-wrapper {
        background: #ffffff;
        border: 2px solid #3176bc;
        border-radius: 0.52rem;
        display: flex;
        align-items: center;
        padding: 0.84rem 0.84rem 0.84rem 0.84rem; }
        .one-click .content > div .input-wrapper svg {
          margin-right: 0.42rem; }
        .one-click .content > div .input-wrapper input {
          border: 0;
          width: 19.4rem;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 0.73rem;
          line-height: 1.26rem;
          letter-spacing: 0.1px;
          color: #3176bc; }
          .one-click .content > div .input-wrapper input::-webkit-input-placeholder {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.73rem;
            line-height: 1.26rem;
            letter-spacing: 0.1px;
            color: #3176bc;
            opacity: 0.8; }
          .one-click .content > div .input-wrapper input::placeholder {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.73rem;
            line-height: 1.26rem;
            letter-spacing: 0.1px;
            color: #3176bc;
            opacity: 0.8; }
      .one-click .content > div button {
        margin-top: 0.63rem;
        -webkit-filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
        background: #3176bc;
        box-shadow: 0px 0px 16px rgba(49, 118, 188, 0.18), inset 0px 4px 12px rgba(28, 144, 227, 0.18);
        border-radius: 10px;
        border: 0;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.73rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: #ffffff;
        padding: 0.76rem 1.9rem; }
        .one-click .content > div button span {
          font-weight: 800; }

.last-f .text {
  font-weight: 600 !important;
  font-size: 0.68rem !important;
  line-height: 1.05rem !important;
  opacity: 0.7 !important;
  text-shadow: unset !important; }

.folowers {
  display: flex;
  background: #ffffff;
  border: 1px solid;
  box-shadow: 0px 6px 12px rgba(109, 141, 173, 0.25);
  border-radius: 10px;
  padding: 0.73rem 1.10rem;
  max-width: 12.94rem;
  margin-bottom: 0.52rem;
  align-items: center; }
  @media (max-width: 991px) {
    .folowers {
      max-width: 17.94rem; } }
  @media (max-width: 767px) {
    .folowers {
      max-width: unset; } }
  .folowers svg {
    margin-right: 0.84rem;
    width: unset !important;
    min-width: 2.63rem; }
    @media (max-width: 767px) {
      .folowers svg {
        min-width: 3.63rem !important; } }
  .folowers .numbers {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 1.26rem;
    letter-spacing: 0.1px; }
  .folowers .text {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.57rem;
    line-height: 1.10rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.6;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.input-text-wrapper label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.73rem;
  line-height: 1.26rem;
  letter-spacing: 0.1px;
  color: #010101; }

.input-text-wrapper .info svg {
  float: left;
  margin-right: 0.52rem; }

.input-text-wrapper .info p {
  opacity: 0.8; }

.input-text-wrapper .desc p {
  font-weight: 500; }

.input-text-wrapper .input-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0.57rem; }
  .input-text-wrapper .input-wrapper input {
    border: 2px solid rgba(49, 118, 188, 0.7);
    box-shadow: 0px 0px 12px rgba(109, 141, 173, 0.13), inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding-left: 2.94rem;
    padding-bottom: 0.78rem;
    padding-top: 0.78rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 1.26rem;
    letter-spacing: 0.1px;
    color: #3176bc; }
  .input-text-wrapper .input-wrapper svg {
    position: absolute;
    left: 1.15rem;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2; }

.not-found-page-wrapper .not-found-page {
  margin-top: 4.36rem;
  margin-bottom: 4.15rem;
  padding-left: 15.26rem;
  padding-right: 15.26rem; }
  @media (max-width: 1024px) {
    .not-found-page-wrapper .not-found-page {
      padding-left: 5rem;
      padding-right: 5rem;
      text-align: center; } }
  @media (max-width: 767px) {
    .not-found-page-wrapper .not-found-page {
      padding-left: 2.15rem;
      padding-right: 2.15rem; } }
  .not-found-page-wrapper .not-found-page > div {
    display: flex;
    align-items: center;
    margin-bottom: 4.15rem; }
    @media (max-width: 1366px) {
      .not-found-page-wrapper .not-found-page > div {
        flex-direction: column-reverse;
        justify-content: center; } }
    .not-found-page-wrapper .not-found-page > div > div {
      margin-right: 2rem; }
      @media (max-width: 1024px) {
        .not-found-page-wrapper .not-found-page > div > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: unset; } }
      .not-found-page-wrapper .not-found-page > div > div h1 {
        font-family: "Qartella", sans-serif;
        font-weight: 700;
        font-size: 2.52rem;
        line-height: 3.05rem;
        color: #010101;
        margin: 0;
        margin-bottom: 1.26rem; }
        @media (max-width: 1024px) {
          .not-found-page-wrapper .not-found-page > div > div h1 {
            font-size: 1.68rem;
            line-height: 2rem; } }
      .not-found-page-wrapper .not-found-page > div > div p {
        font-weight: 400;
        font-size: 0.84rem;
        line-height: 1.47rem;
        color: #010101;
        margin: 0;
        margin-bottom: 1.68rem; }
        .not-found-page-wrapper .not-found-page > div > div p a {
          color: #26ade3;
          text-decoration: unset; }
      .not-found-page-wrapper .not-found-page > div > div button {
        -webkit-filter: drop-shadow(0px 0px 12px rgba(38, 173, 227, 0.25));
                filter: drop-shadow(0px 0px 12px rgba(38, 173, 227, 0.25));
        background-color: transparent;
        border: 3px solid #26ade3;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.84rem;
        line-height: 1.47rem;
        color: #26ade3;
        padding: 0.47rem 1.36rem; }
        .not-found-page-wrapper .not-found-page > div > div button svg {
          margin-right: 0.5rem; }
  .not-found-page-wrapper .not-found-page a {
    font-family: "Qartella", sans-serif;
    font-weight: 700;
    font-size: 0.84rem;
    line-height: 1.47rem;
    color: #010101;
    text-decoration: unset; }
  .not-found-page-wrapper .not-found-page img {
    height: 19.68rem;
    object-fit: contain; }
    @media (max-width: 1024px) {
      .not-found-page-wrapper .not-found-page img {
        max-width: 29.15rem; } }
    @media (max-width: 991px) {
      .not-found-page-wrapper .not-found-page img {
        max-width: 29.15rem; } }
    @media (max-width: 767px) {
      .not-found-page-wrapper .not-found-page img {
        max-width: 100%;
        object-fit: contain; } }

@-webkit-keyframes grow-pulse {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0; }
  30% {
    opacity: 0.5; }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes grow-pulse {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0; }
  30% {
    opacity: 0.5; }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0; }
  100% {
    opacity: 0; } }

.spinner-wrapper {
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
  background-size: 100% auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh; }
  .spinner-wrapper .example {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    height: 100vh;
    position: relative;
    letter-spacing: 0.5px; }
  .spinner-wrapper .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 10rem;
    height: 10rem;
    position: absolute;
    top: 40%;
    left: 45%;
    border-radius: 50%;
    -webkit-transform: translate(-40%, -45%);
            transform: translate(-40%, -45%); }
  .spinner-wrapper .spinner.grow-pulse::after {
    background-color: white;
    -webkit-animation: grow-pulse 1.5s ease-out infinite;
            animation: grow-pulse 1.5s ease-out infinite; }

.search-phone-wrapper {
  max-width: 20%; }
  .search-phone-wrapper .search-phone {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #3176bc;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
    height: 2.73rem; }
    .search-phone-wrapper .search-phone input {
      border: 0;
      outline: none;
      width: 100%;
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.86rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      padding: 0.78rem 1.36rem; }
    .search-phone-wrapper .search-phone svg:first-child {
      margin-left: 0.5rem; }
    @media (max-width: 767px) {
      .search-phone-wrapper .search-phone {
        margin-top: 1.05rem;
        margin-bottom: 1.05rem; } }
    .search-phone-wrapper .search-phone h6 {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.86rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      padding: 0.78rem 1.36rem; }
    .search-phone-wrapper .search-phone .result {
      top: 2.8rem;
      position: absolute;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      width: 100%;
      background-color: #ffffff;
      z-index: 99;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      max-height: 520px;
      overflow: hidden;
      overflow-y: auto; }
      .search-phone-wrapper .search-phone .result::-webkit-scrollbar {
        width: 10px; }
      .search-phone-wrapper .search-phone .result::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey; }
      .search-phone-wrapper .search-phone .result::-webkit-scrollbar-thumb {
        background: #3176bc;
        border-radius: 10px; }
      .search-phone-wrapper .search-phone .result::-webkit-scrollbar-thumb:hover {
        background: #3176bc; }
      .search-phone-wrapper .search-phone .result h6 {
        white-space: nowrap; }
        .search-phone-wrapper .search-phone .result h6:hover {
          background-color: #3176bc;
          color: #ffffff; }
  .search-phone-wrapper .error {
    color: red;
    position: absolute;
    font-size: 0.84rem;
    line-height: 1.26rem; }

.checkbox {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */ }
  .checkbox .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none; }
  .checkbox .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid #3176BCed; }
  .checkbox .container input:checked ~ .checkmark {
    border: 2px solid #3176BC; }
  .checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox .container input:checked ~ .checkmark:after {
    display: block; }
  .checkbox .container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: #3176BC; }

.basic-info-wrapper {
  display: flex;
  width: 100%;
  grid-column-gap: 1.05rem;
  -webkit-column-gap: 1.05rem;
          column-gap: 1.05rem; }
  @media (max-width: 1024px) {
    .basic-info-wrapper {
      flex-direction: column;
      grid-row-gap: 1.05rem;
      row-gap: 1.05rem; } }
  .basic-info-wrapper .basic-info {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(109, 141, 173, 0.08);
    border-radius: 15px;
    padding: 1.89rem 1.26rem; }
    .basic-info-wrapper .basic-info .title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 1.05rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8;
      margin-bottom: 1.05rem; }
    .basic-info-wrapper .basic-info .input input {
      font-family: 'Poppins';
      font-style: normal;
      font-size: 0.88rem;
      background: rgba(218, 218, 218, 0.3);
      border: 1px solid rgba(218, 218, 218, 0.6);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      padding: 18px; }
    .basic-info-wrapper .basic-info .input {
      display: flex;
      justify-content: space-between; }

.details {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(109, 141, 173, 0.08);
  border-radius: 15px;
  padding: 36px 24px 24px 24px; }
  .details .title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.05rem;
    line-height: 1.1rem;
    letter-spacing: 0.1px;
    color: #010101;
    opacity: 0.8;
    margin-bottom: 1.05rem; }
  .details .textarea {
    background: rgba(218, 218, 218, 0.3);
    border: 1px solid rgba(218, 218, 218, 0.6);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    overflow: hidden;
    position: relative; }
    .details .textarea textarea {
      background: transparent;
      width: 100%;
      min-height: 8.57rem;
      border: 0;
      padding: 0.84rem;
      font-family: "Poppins", sans-serif;
      font-size: 0.88rem;
      line-height: 1.31rem;
      letter-spacing: 0.1px; }
      .details .textarea textarea::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
        opacity: 0.8; }
      .details .textarea textarea::placeholder {
        color: rgba(0, 0, 0, 0.4);
        opacity: 0.8; }
    .details .textarea .numbers {
      position: absolute;
      right: 1.05rem;
      bottom: 0.525rem;
      font-family: "Poppins", sans-serif;
      font-size: 0.73rem;
      line-height: 1.10rem;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 0.8; }
  .details .copy {
    background: rgba(218, 218, 218, 0.1);
    border: 1px solid rgba(218, 218, 218, 0.6);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 1.10rem;
    letter-spacing: 1px;
    color: #010101;
    opacity: 0.8;
    padding: 0.84rem 1.89rem;
    transition: 0.3s;
    margin-top: 0.84rem; }
    .details .copy:hover {
      background: rgba(189, 185, 185, 0.1);
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.all-listing-wrapper {
  background: rgba(218, 218, 218, 0.15);
  box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
  margin: 1rem 2.1rem 3.36rem 2.1rem;
  box-sizing: border-box;
  padding-bottom: 5rem;
  padding-top: 3.26rem; }
  @media (max-width: 991px) {
    .all-listing-wrapper {
      padding-left: 1.68rem;
      padding-right: 1.68rem; } }
  @media (max-width: 767px) {
    .all-listing-wrapper {
      padding-left: 0.78rem;
      padding-right: 0.78rem; } }
  .all-listing-wrapper *,
  .all-listing-wrapper *:before,
  .all-listing-wrapper *:after {
    box-sizing: inherit; }
  @media (max-width: 767px) {
    .all-listing-wrapper {
      margin: 1rem 0.5rem 3.36rem 0.5rem; } }
  .all-listing-wrapper .error {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.73rem;
    line-height: 0.84rem;
    letter-spacing: 0.1px;
    color: #d11a2a;
    opacity: 0.8;
    width: 100%;
    white-space: nowrap;
    margin-top: 0.84rem; }
  .all-listing-wrapper .header {
    text-align: center;
    padding-bottom: 3.15rem; }
    .all-listing-wrapper .header img {
      width: unset; }
    .all-listing-wrapper .header .desc {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }
      .all-listing-wrapper .header .desc svg {
        margin-right: 0.42rem; }
  .all-listing-wrapper .wrapper-middle {
    margin-top: 1rem;
    grid-column-gap: 1.05rem;
    -webkit-column-gap: 1.05rem;
            column-gap: 1.05rem;
    display: grid;
    grid-template-columns: 5fr 7fr 6fr; }
    @media (max-width: 767px) {
      .all-listing-wrapper .wrapper-middle .card-wrapper .checkbox {
        margin-bottom: 1.5rem; } }
    @media (max-width: 1024px) {
      .all-listing-wrapper .wrapper-middle {
        grid-row-gap: 1.05rem;
        row-gap: 1.05rem;
        grid-template-columns: 12fr; } }
    .all-listing-wrapper .wrapper-middle .last-card-wrapper .card-wrapper:last-child {
      margin-top: 1.05rem; }
    .all-listing-wrapper .wrapper-middle .custom {
      height: 58%; }
      @media (max-width: 1024px) {
        .all-listing-wrapper .wrapper-middle .custom {
          height: unset; } }
    .all-listing-wrapper .wrapper-middle .card-wrapper {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 50px rgba(109, 141, 173, 0.08);
      border-radius: 15px;
      padding: 1.89rem 1.26rem; }
      .all-listing-wrapper .wrapper-middle .card-wrapper .checkbox {
        justify-content: unset; }
      .all-listing-wrapper .wrapper-middle .card-wrapper .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1.05rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        color: #010101;
        opacity: 0.8;
        margin-bottom: 1.05rem; }
      .all-listing-wrapper .wrapper-middle .card-wrapper .desc {
        font-family: "Poppins", sans-serif;
        font-size: 0.88rem;
        line-height: 1.26rem;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 0.6; }
      .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper {
        margin-bottom: 0.55rem;
        display: grid;
        grid-template-columns: 5fr 7fr; }
        @media (max-width: 991px) {
          .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper {
            grid-template-columns: 5fr 6fr; } }
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .label, .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper {
          background: rgba(218, 218, 218, 0.4);
          border: 1px solid rgba(218, 218, 218, 0.6);
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
          border-radius: 5px 0px 0px 5px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 0.88rem;
          line-height: 0.84rem;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.9);
          padding: 18px 16px 18px 16px;
          white-space: nowrap; }
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper {
          padding: 0;
          height: unset;
          opacity: unset;
          padding-right: 0.6rem;
          margin: 0; }
          .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .up {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
          .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper h6 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.88rem;
            line-height: 0.84rem;
            letter-spacing: 0.1px;
            color: rgba(0, 0, 0, 0.9);
            opacity: unset;
            padding: 18px 16px 18px 16px; }
          .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .result {
            border-radius: 0;
            padding-top: 0;
            padding-bottom: 0; }
            .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper .select-input-wrapper .result h6:hover {
              color: #ffffff;
              background-color: #010101; }
        .all-listing-wrapper .wrapper-middle .card-wrapper .inputs .input-wrapper input {
          background: rgba(218, 218, 218, 0.3);
          border: 1px solid rgba(218, 218, 218, 0.6);
          border-radius: 0px 5px 5px 0px;
          font-family: "Poppins", sans-serif;
          font-size: 0.88rem;
          line-height: 0.84rem;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 0.6;
          padding: 15px 16px 13.4px 16px;
          width: 100%; }
      .all-listing-wrapper .wrapper-middle .card-wrapper .buttons {
        display: flex;
        grid-column-gap: 0.82rem;
        -webkit-column-gap: 0.82rem;
                column-gap: 0.82rem; }
        .all-listing-wrapper .wrapper-middle .card-wrapper .buttons button {
          background: #ffffff;
          opacity: 0.6;
          border: 1px solid rgba(0, 0, 0, 0.8);
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
          border-radius: 5px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 0.94rem;
          line-height: 0.84rem;
          display: flex;
          align-items: center;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.8);
          padding: 0.94rem 1.21rem;
          transition: 0.3s; }
          .all-listing-wrapper .wrapper-middle .card-wrapper .buttons button:hover {
            background-color: rgba(218, 218, 218, 0.6); }
        .all-listing-wrapper .wrapper-middle .card-wrapper .buttons .active {
          background-color: #3176bc;
          opacity: 2;
          color: #ffffff;
          border: 0; }
          .all-listing-wrapper .wrapper-middle .card-wrapper .buttons .active:hover {
            background-color: #3176bc; }
    .all-listing-wrapper .wrapper-middle .basic-house-attributes {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 50px rgba(109, 141, 173, 0.08);
      border-radius: 15px;
      padding: 1.89rem 1.26rem; }
  .all-listing-wrapper .details-wrapper {
    margin-top: 1.05rem;
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 1.05rem;
    gap: 1.05rem; }
    @media (max-width: 991px) {
      .all-listing-wrapper .details-wrapper {
        grid-template-columns: 6fr; } }
  .all-listing-wrapper .result-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.52rem; }
    .all-listing-wrapper .result-wrapper .submit {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .all-listing-wrapper .result-wrapper .submit .submit {
        margin-bottom: 1.68rem;
        background: #3176bc;
        box-shadow: 0px 0px 16px rgba(109, 141, 173, 0.25), inset 0px 4px 12px rgba(255, 255, 255, 0.13);
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 0.88rem;
        line-height: 1.1rem;
        letter-spacing: 1px;
        color: #ffffff;
        border: 0;
        padding: 1.26rem 6.31rem;
        max-width: 441px;
        transition: 0.3s;
        white-space: nowrap; }
        .all-listing-wrapper .result-wrapper .submit .submit:disabled {
          opacity: 0.8;
          cursor: not-allowed; }
          .all-listing-wrapper .result-wrapper .submit .submit:disabled:hover {
            -webkit-transform: scale(1);
                    transform: scale(1); }
        .all-listing-wrapper .result-wrapper .submit .submit:hover {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
      .all-listing-wrapper .result-wrapper .submit .result-shadow {
        background: rgba(218, 218, 218, 0.05);
        box-shadow: 0px 0px 50px rgba(109, 141, 173, 0.12);
        border-radius: 15px;
        padding: 1rem;
        width: 85%; }
        @media (max-width: 991px) {
          .all-listing-wrapper .result-wrapper .submit .result-shadow {
            width: 100%; } }
        .all-listing-wrapper .result-wrapper .submit .result-shadow .details {
          width: 100%; }
          .all-listing-wrapper .result-wrapper .submit .result-shadow .details .textarea textarea {
            min-height: 245px;
            width: 100%; }

.modal-content-wrapper {
  background: rgba(1, 1, 1, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }
  .modal-content-wrapper .modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden; }

.billing-modal-wrapper {
  background: #ffffff;
  border-radius: 5px;
  padding: 2.21rem 1.26rem 2.21rem 1.31rem; }
  @media (max-width: 767px) {
    .billing-modal-wrapper {
      padding: 2.21rem 1.26rem 2.21rem 0.31rem; } }
  .billing-modal-wrapper .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.73rem;
    padding-left: 1rem; }
    .billing-modal-wrapper .header .title {
      font-family: "Nunito", sans-serif;
      font-weight: 800;
      font-size: 1.47rem;
      line-height: 1.1rem;
      letter-spacing: 0.1px;
      color: #010101;
      opacity: 0.8; }
    .billing-modal-wrapper .header .close {
      cursor: pointer; }
  .billing-modal-wrapper .table-wrapper .table .table-header {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.84rem;
    max-width: 46.63rem; }
    @media (max-width: 991px) {
      .billing-modal-wrapper .table-wrapper .table .table-header {
        display: none; } }
    .billing-modal-wrapper .table-wrapper .table .table-header > div {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.84rem;
      line-height: 1.05rem;
      letter-spacing: 0.1px;
      background: #3176bc;
      padding: 13.5px 3.57rem;
      white-space: nowrap;
      color: #ffffff;
      margin-right: 0.42rem; }
      .billing-modal-wrapper .table-wrapper .table .table-header > div:nth-child(2) {
        padding-left: 17.5px;
        padding-right: 17.5px; }
      .billing-modal-wrapper .table-wrapper .table .table-header > div:nth-child(3) {
        padding-left: 3.05rem;
        padding-right: 3.05rem; }
      .billing-modal-wrapper .table-wrapper .table .table-header > div:nth-child(4) {
        padding-left: 4.26rem;
        padding-right: 4.26rem;
        margin: 0; }
  .billing-modal-wrapper .table-wrapper .table .content-wrapper {
    max-height: 18.94rem;
    overflow-y: auto;
    width: 56.8rem;
    padding-left: 1rem; }
    @media (max-width: 991px) {
      .billing-modal-wrapper .table-wrapper .table .content-wrapper {
        width: 42.8rem; } }
    @media (max-width: 767px) {
      .billing-modal-wrapper .table-wrapper .table .content-wrapper {
        width: 22.8rem;
        padding-right: 0.8rem; } }
    .billing-modal-wrapper .table-wrapper .table .content-wrapper::-webkit-scrollbar {
      width: 6px; }
    .billing-modal-wrapper .table-wrapper .table .content-wrapper::-webkit-scrollbar-thumb {
      background: #3176bc;
      border-radius: 5px; }
    .billing-modal-wrapper .table-wrapper .table .content-wrapper::-webkit-scrollbar-track {
      background: #d9d9d9; }
    .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper {
      display: flex;
      align-items: center; }
      .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row {
        max-width: 46.63rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.71rem 0;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.84rem;
        line-height: 1.01rem;
        text-align: center;
        letter-spacing: 0.1px;
        color: #010101;
        background: #ffffff;
        box-shadow: 0px 0px 20px rgba(109, 141, 173, 0.13);
        margin-bottom: 0.31rem; }
        @media (max-width: 991px) {
          .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row {
            max-width: 34.63rem; } }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row:first-child {
          font-weight: 600; }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row:last-child {
          font-weight: 600; }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row > div:first-child {
          padding-left: 35px; }
          @media (max-width: 767px) {
            .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row > div:first-child {
              padding-left: 25px; } }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row > div:last-child {
          padding-right: 35px; }
          @media (max-width: 767px) {
            .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row > div:last-child {
              padding-right: 25px; } }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row .Success {
          color: #037547; }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .row .Decline {
          color: #d0342c; }
      .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .options {
        display: flex;
        align-items: center;
        margin-left: 1.73rem; }
        @media (max-width: 991px) {
          .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .options {
            margin-left: 0.73rem; } }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .options button {
          background: transparent;
          border: 0;
          margin-right: 1.73rem;
          transition: 0.3s; }
          @media (max-width: 991px) {
            .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .options button {
              margin-right: 0.73rem; } }
          .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .options button:hover {
            -webkit-transform: scale(1.25);
                    transform: scale(1.25); }
      .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .row-wrapper .drop {
        background-color: #010101;
        border: 0;
        padding: 6px 10px;
        margin-left: 0.42rem; }
    .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu {
      background: #ffffff;
      border: 1px solid #dadada;
      padding: 0.73rem; }
      .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu p {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.84rem;
        line-height: 1.05rem;
        letter-spacing: 0.1px; }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu p .Success {
          color: #037547;
          font-weight: 600; }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu p .Decline {
          color: #d0342c;
          font-weight: 600; }
      .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu .options button {
        background: transparent;
        border: 0;
        margin-right: 1.73rem;
        transition: 0.3s; }
        @media (max-width: 991px) {
          .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu .options button {
            margin-right: 0.73rem; } }
        .billing-modal-wrapper .table-wrapper .table .content-wrapper .content .menu .options button:hover {
          -webkit-transform: scale(1.25);
                  transform: scale(1.25); }

.LoadingBar_page-loading-bar__33GDH {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.LoadingBar_page-loading-bar__33GDH:after {
    content: " ";
    display: block;
    width: 48px;
    height: 48px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(48, 48, 48);
    border-color: rgb(48, 48, 48) transparent rgb(48, 48, 48) transparent;
    -webkit-animation: LoadingBar_lds-dual-ring__3Dv00 1s linear infinite;
            animation: LoadingBar_lds-dual-ring__3Dv00 1s linear infinite;
}

@-webkit-keyframes LoadingBar_lds-dual-ring__3Dv00 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

@keyframes LoadingBar_lds-dual-ring__3Dv00 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

.GmapLocationPicker_location-picker__container__2z1vv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(48, 48, 48, 0.3);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GmapLocationPicker_location-picker__gui__2Pi3G {
    width: 60%;
    height: 60%;
}

.GmapLocationPicker_location-picker__ctrl__1-Z54 {
    background-color: white;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;
}

.GmapLocationPicker_location-picker__map__SA8FT {
    width: 100%;
    height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

